const itemsData = [
  { title: 'Global  Tournaments with Unique Formats', description: 'Host exclusive tournaments with innovative playing formats.', iconName: 'icon_cup', },
  // { title: 'Integrated Learning Tools', description: 'Host exclusive tournaments with innovative playing formats.', iconName: 'icon_wrench', },
  { title: 'Social Networking', description: 'Incorporate robust social features, enabling users to form clubs, host events, and engage in community discussions.', iconName: 'icon_neirons', },
  // { title: 'VR/AR Integration', description: 'Allow players to engage in games using virtual or augment-ed reality for a more immersive experience.', iconName: 'icon_vr', },

  // { title: 'Advanced AI Opponents', description: "Implement AI that not only plays at different levels but also mimics famous players' styles.", iconName: 'icon_vs', },
  // { title: 'Customizable 3D Boards & Pieces', description: 'Offer extensive customization options for 3D boards and chess pieces.', iconName: 'icon_knight_board', },
  // { title: 'Real-time Analytics and Insights', description: 'Provide players with real-time game analysis and personalized tips for improvement.', iconName: 'icon_graphic', },
  // { title: 'Voice Command Functionality', description: 'Allow players to move pieces using voice commands.', iconName: 'icon_voice', },

  // { title: 'Multiplayer Modes', description: 'Including team-based play or pair chess options.', iconName: 'icon_persons', },
  { title: 'Community & Social Features', description: 'Including team-based play or pair chess options.', iconName: 'icon_neirons', },
  { title: 'Tournaments and Events', description: 'Including team-based play or pair chess options.', iconName: 'icon_calendar', },
  // { title: 'New strong chess engine', description: 'Including team-based play or pair chess options.', iconName: 'icon_mechanical', },
];

export default itemsData;
