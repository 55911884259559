// libs
import { useState } from "react";
import { useSelector } from "react-redux";

// components
import CustomInputForModal from "../../../../custom/CustomInputForModal";
import CustomButton from "../../../../custom/CustomButton";

// other
import API from "../../../../../api/api";
import usePassword from "../../../../../customHooks/usePassword";

// styles
import classes from "./styles.module.css";

export default function ChangeUsername({ closeModal }) {
  const [username, setUsername] = useState("");
  const [currentPassword, _, setCurrentPassword] = usePassword("");
  const {userId, accessToken} = useSelector(s => s.userAuthSlice);

  const doSaveButton = () => {
    // API.updateProfileUsername(userId, currentPassword, username, accessToken)
    // .then(d => {
    //   if (d.message === 'updated') {
    //     closeModal();
    //   }
    // });
  }

  return (
    <div>
      <CustomInputForModal
        title="New Username"
        value={username}
        isLargeVersion={true}
        setValue={setUsername}
      />

      <CustomInputForModal
        title="Your Password"
        isLargeVersion={true}
        value={currentPassword}
        setValue={setCurrentPassword}
      />

      <CustomButton className={classes.button} buttonText="Save" onClick={doSaveButton}/>
    </div>
  );
}
