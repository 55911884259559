// libs
import { useEffect, useState } from "react";

// components
import Skeleton from "../../../../../other/Skeleton/Skeleton";

// others
import API from "../../../../../../api/api";

export default function Player({ playerUserId, classes }) {
  const [playerData, setPlayerData] = useState(null);

  useEffect(() => {
    // API.getProfileInfo(playerUserId)
    // .then(d => {
    //   if (d?.message === 'foundOneUser') {
    //     setPlayerData(d.data);
    //   }
    // });
  }, []);

  return (
    <div className={classes.profile}>
      <Skeleton
        imgSrc={((playerData && playerData?.profilePhoto) ? API.img(playerData.profilePhoto) : '/default_profile_picture.png')}
        imgAlt="profile_image"
        imgClassName={classes.profileImg}
      />
      <p className={classes.username}>username</p>
    </div>
  );
}
