// libs
import { configureStore } from '@reduxjs/toolkit';

// slices
import chessBoardSlice from './slices/chessBoardSlice';
import currentFigureSlice from './slices/currentFigureSlice';
import userAuthSlice from './slices/userAuthSlice';
import userDataSlice from './slices/userDataSlice';
import notificationSlice from './slices/notificationSlice';

const store = configureStore({
  reducer: {
    chessBoardSlice,
    currentFigureSlice,
    userAuthSlice,
    userDataSlice,
    notificationSlice,
  }
});

export default store;
