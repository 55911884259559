/* eslint-disable react-hooks/exhaustive-deps */
// libs
import React from "react";
import { useSelector } from "react-redux";

// components
import SquareNotConnection from "../SquareNotConnection/SquareNotConnection";
import Opponent from "../Opponent";

// styles
import classes from "./styles.module.css";

export default function ChessboardNotConnection() {
  const { chessBoard } = useSelector((s) => s.chessBoardSlice);

  const List = ({ list, className }) => {
    return (
      <div className={className}>
        {list.map((item, i) => (
          <p key={i}>{item}</p>
        ))}
      </div>
    );
  };

  const boardChars = ["a", "b", "c", "d", "e", "f", "g", "h"];
  const boardNumbers = [8, 7, 6, 5, 4, 3, 2, 1];

  let colorCounter = 1;
  return (
    <div>
      <div>
        <Opponent img={"http://surl.li/qjzln"} username={"Steve"} />
        <div className={classes.chessBoardBox}>
          <div className={classes.chessBoard}>
            <List list={boardNumbers} className={classes.boardNumbers} />
            <div>
              <List list={boardChars} className={classes.boardChars} />
              <div className={classes.board}>
                {chessBoard &&
                  chessBoard.map((line, i) => {
                    ++colorCounter;
                    return line.map((squareData, j) => {
                      ++colorCounter;
                      return (
                        <SquareNotConnection
                          key={`key_square_${i}:${j}`}
                          squareData={squareData}
                          i={i}
                          j={j}
                          colorCounter={colorCounter}
                        />
                      );
                    });
                  })}
              </div>
              <List list={boardChars} className={classes.boardChars} />
            </div>
            <List list={boardNumbers} className={classes.boardNumbers} />
          </div>
        </div>
        <Opponent img={"http://surl.li/qjzln"} username={"Alex"} />
      </div>
    </div>
  );
}
