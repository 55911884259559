// libs
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// other
import API from "../api/api";
import ROUTES from "../routes/routes";

// slice
import { setAccessToken, setRefreshToken } from "../store/slices/userAuthSlice";

export default function useRefreshToken() {
  const { refreshToken } = useSelector((s) => s.userAuthSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updateRefreshToken = () => {
    API.refreshToken(refreshToken).then((d) => {
      if (d?.error?.message === "Access Denied: No token provided") {
        navigate(ROUTES.USER_LOGOUT);
      } else if (d?.newTokens) {
        dispatch(setAccessToken(d?.newTokens.accessToken));
        dispatch(setRefreshToken(d?.newTokens.refreshToken));
      }
    });
  };

  return [refreshToken, updateRefreshToken];
}
