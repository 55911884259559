// libs
import { useState } from "react";

// components
import PlayingNow from '../PlayingNow';
import RecentGames from '../RecentGames';

// styles
import classes from "./styles.module.css";

export default function FrendsPlaying() {
  const [activeSection, setActiveSection] = useState(0);

  const showPlayingNow = () => {
    setActiveSection(0);
  };
  const showRecentGames = () => {
    setActiveSection(1);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <button className={`${classes.button} ${(activeSection === 0 && classes.buttonActive)}`} onClick={showPlayingNow}>
          Playing Now
        </button>
        <button className={`${classes.button} ${(activeSection === 1 && classes.buttonActive)}`} onClick={showRecentGames}>
          Recent Games
        </button>
      </div>

      <div className={classes.content}>
        {activeSection === 0 && <PlayingNow />}
        {activeSection === 1 && <RecentGames />}
      </div>
    </div>
  );
}
