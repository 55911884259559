// libs
import { useState, useEffect } from 'react';

// components
import Skeleton from '../../../../../components/other/Skeleton/Skeleton';

// other
import API from '../../../../../api/api';

// styles
import classes from './styles.module.css';

export default function OnlineFriendsOpponent({ opponentId, setFriendId }) {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    API.getProfileInfo(opponentId)
    .then((d) => {
      if (d?.message === 'findOneUser') {
        setUserData(d.data);
      }
    });
  }, []);

  console.log(userData);

  return (
    <div className={classes.root} onClick={() => setFriendId(opponentId)}>
      <Skeleton 
        imgSrc={(userData && userData?.profilePhoto) ? API.img(userData.profilePhoto) : '/default_profile_picture.png' }
        imgClassName={classes.img}
      />
    </div>
  )
}