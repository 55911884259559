// styles 
import classes from './styles.module.css';

export default function CustomToggle({ value, setValue, label, className = "" }) {
  return (
    <div className={`${classes.root} ${className}`} onClick={() => setValue(prev => !prev)}>
      <div className={`${classes.circleBox} ${value && classes.toggled}`}>
        <div className={classes.circle}></div>
      </div>
      <p className={classes.label}>{label}</p>
    </div>
  )
}
