// libs
import io from "socket.io-client";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// components
// import Chessboard from "../../../components/other/Chessboard";
import ChessboardNotConnection from "../../../components/other/ChessboardNotConnection";
import Buttons from "./widgets/Buttons";
import ShowedMoves from "../../../components/other/ShowedMoves/ShowedMoves";

// other
import SERVER_PATH from "../../../api/serverPath";

// slices 
import { setIsInviteApplied, removeGameInvite } from "../../../store/slices/notificationSlice";
import { setGameId } from "../../../store/slices/userAuthSlice";

// styles
import classes from "./styles.module.css";

export default function UserPlay() {
  const [isSocketReady, setIsSocketReady] = useState(false);
  const [rightSideBarItemIndex, setRightSideBarItemIndex] = useState(0);
  
  const { accessToken, gameId } = useSelector((s) => s.userAuthSlice);
  const {isInviteApplied, gamesInvites} = useSelector(s => s.notificationSlice);

  const socketRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('#1', gamesInvites);
    if (isSocketReady && isInviteApplied && gamesInvites[0]) {
      socketRef.current.emit('getGame', {gameId: gamesInvites[0]?.gameId});
      dispatch(setGameId(gamesInvites[0].gameId));
      dispatch(removeGameInvite(0));
      dispatch(setIsInviteApplied(false));
    }
  }, [isInviteApplied, isSocketReady]);

  useEffect(() => {
    console.log("%cpage: UserPlay", "color: orange");

    if (!socketRef.current) {
      console.log("- is not connected");

      socketRef.current = io(SERVER_PATH, {
        auth: { token: accessToken },
      });
      
      socketRef.current.on("connect", () => {
        console.log("%csocket.on: CONNECTED", "color: yellow");
        setIsSocketReady(true);
      });
    } else {
      console.log("- is connected");
    }

    return () => {
      if (socketRef.current.connected) {
        socketRef.current.disconnect();
        console.log("%csocket.on: DISCONNECTED", "color: yellow");
      }
    };
  }, [accessToken]);

  useEffect(() => {
    // calculate rightSideBarItemIndex and show showed right side bar
    if (gameId) {
      setRightSideBarItemIndex(1);
    }
  }, [gameId]);

  return (
    <div className={classes.root}>
      {/* {isSocketReady ? (
        <Chessboard socket={socketRef.current} />
      ) : ( */}
        <ChessboardNotConnection />
      {/* // )} */}

      {/* RIGHTS SIDE BARS */}
      {rightSideBarItemIndex === 0 && (
        <Buttons
          socket={socketRef.current}
          classes={classes}
          isSocketReady={isSocketReady}
        />
      )}

      {rightSideBarItemIndex === 1 &&
        <ShowedMoves />
      }
    </div>
  );
}
