// libs
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// other
import ROUTES from "../../../routes/routes";

// styles
// import classes from "./styles.module.css";

export default function AuthCreateLoginPass() {
  const [timerSeconds, setTimerSeconds] = useState(3);

  const navigate = useNavigate();

  useEffect(() => {
    const oneSecond = 1000;
    const timer = setInterval(() => {
      setTimerSeconds(prev => prev - 1);
    }, oneSecond);

    return () => {
      clearInterval(timer);
    }
  }, []);

  useEffect(() => {
    if (timerSeconds < 1) {
      navigate(ROUTES.SIGN_IN);
    }
  }, [timerSeconds]);

  return (
    <div>
      <h1 className="authHeaderText2">Your account is activated!</h1>
      <p>Redirect {timerSeconds} sec..</p>
    </div>
  );
}
