// libs
import { forwardRef } from "react";

// components
import UserPageShortPosts from "../UserPageShortPosts";
import UserPagePlayedGames from "../UserPagePlayedGames";
import UserPageTournaments from "../UserPageTournaments";
import UserPageFriends from "../UserPageFriends/UserPageFriends";
import UserPageGameEvents from "../UserPageGameEvents";

// styles
import classes from "./styles.module.css";

const UserProfileContent = forwardRef(function UserProfileContent(
  { userData, activeTab, showAddNewPost, setShowAddNewPost },
  ref
) {

  return (
    <div className={classes.userContent}>
      {activeTab === 0 && (
        <UserPageShortPosts
          userData={userData}
          showAddNewPost={showAddNewPost}
          closeModal={() => {
            setShowAddNewPost(false);
          }}
          ref={ref}
        />
      )}
      {activeTab === 1 && <UserPagePlayedGames />}
      {activeTab === 2 && <UserPageTournaments />}
      {activeTab === 3 && <UserPageFriends userData={userData} />}
      {activeTab === 4 && <UserPageGameEvents />}
    </div>
  );
});

export default UserProfileContent;
