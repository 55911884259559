// libs
import { Link } from "react-router-dom";

// components
// import SvgIcon from '../../other/SvgIcon';

// other
import ROUTES from "../../../routes/routes";

// styles
import classes from "./styles.module.css";

export default function AuthLoginWith({
  routeToSignIn = false,
  className = "",
}) {
  return (
    <div className={`${classes.root} ${className}`}>
      <div className={classes.header}>
        <div className={classes.line}></div>
        <p>or</p>
        <div className={classes.line}></div>
      </div>

      {/* <div className={classes.links}>
        <Link to="https://facebook.com">
          <SvgIcon iconName="logo_facebook" width={36} height={36} />
        </Link>
        <Link to="https://google.com">
          <SvgIcon iconName="logo_google" width={36} height={36} />
        </Link>
      </div> */}

      <p className={classes.haveAccount}>
        Already have an account?
        {routeToSignIn ? (
          <Link to={ROUTES.SIGN_IN}> Sign In</Link>
        ) : (
          <Link to={ROUTES.CREATE_ACCOUNT}> Sign Up</Link>
        )}
      </p>
    </div>
  );
}
