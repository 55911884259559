const piecesImages = {
  black: {
    king: 'figure_king_black',
    queen: 'figure_queen_black',
    bishop: 'figure_bishop_black',
    knight: 'figure_knight_black',
    rook: 'figure_rook_black',
    pawn: 'figure_pawn_black',
  },
  
  white: {
    king: 'figure_king_white',
    queen: 'figure_queen_white',
    bishop: 'figure_bishop_white',
    knight: 'figure_knight_white',
    rook: 'figure_rook_white',
    pawn: 'figure_pawn_white',
  },
};

export default piecesImages;
