// components
import GameType from '../../../GameType/GameType';
import SvgIcon from '../../../SvgIcon';

// customHooks
import useGameType from '../../../../../customHooks/useGameType';


// styles
import classes from './styles.module.css';

export default function InviteFriendModal({ closeModal, userData }) {
  const [startGameType, setStartGameType] = useGameType(null);

  const sendInviteLink = () => {
    // TODO: send startGameType to server
  };

  return (
    <div className={classes.root}>
      <div className={classes.modalContainer}>
        <button className={classes.closeButton} onClick={closeModal}>
          <SvgIcon iconName="icon_close" width={14} height={14} />
        </button>
        <div>
          <GameType setGameTypes={setStartGameType}/>
          <button className={classes.sendInviteButton} onClick={sendInviteLink}>send invite link</button>
        </div>
      </div>
    </div>
  )
}