/* eslint-disable react-hooks/exhaustive-deps */
// libs
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { forwardRef } from "react";
import { useLocation } from "react-router-dom";

// components
import UserPost from "../UserPost";
import AddNewPost from "../../other/AddNewPost";
import SvgIcon from "../../other/SvgIcon";

// other
// import API from "../../../api/api";

// slices
// import { setAccessToken, setRefreshToken } from "../../../store/slices/userAuthSlice";

// custom hooks
// import useRefreshToken from "../../../customHooks/useRefreshToken";

// styles
import classes from "./styles.module.css";

const UserPageShortPosts = forwardRef(
  function UserPageShortPosts({
    showAddNewPost,
    closeModal,
    userData,
  }, ref) {
    const [postsData, setPostsData] = useState([
      {
        author: "@username_2",
        createdAt: "createdAt",
        filesId: [],
        likersId: [1, 2],
        sheirersId: [],
        title: "Post title",
        _id: "_id",
      },
    ]);
    const [postsPageNumb, setPostsPageNumb] = useState(0);
    const { accessToken } = useSelector((s) => s.userAuthSlice);
    // const [, updateRefreshToken] = useRefreshToken();

    const dispatch = useDispatch();
    const location = useLocation();
    
    const setNewUserPosts = (callBack) => {
      // userData?._id &&
      // API.getUserPostsById(accessToken, postsPageNumb, userData?._id)
      // .then((d) => {
      //   if (d?.newTokens) {
      //     dispatch(setAccessToken(d?.newTokens.accessToken));
      //     dispatch(setRefreshToken(d?.newTokens.refreshToken));
      //   }
      //   if (d?.error?.message === 'Wrong authentication token') {
      //     updateRefreshToken();
      //   }
        
      //   if (d?.data?.data) {
      //     callBack(d.data.data)
      //   }
      // })
    }

    const fetchingUserPosts = () => {
      setNewUserPosts((data) => {
        setPostsData(prev => [...prev, ...data]);
      });
    }

    const fetchingUserPostsAfterAddNewPost = () => {
      setNewUserPosts((data) => {
        setPostsData(prev => [data[0], ...prev]);
        closeModal();
      });
    }

    const fetchingAndSetUserPost = () => {
      setNewUserPosts((data) => {
        setPostsData(data);
      });
    }

    useEffect(() => {
      if (!userData?._id) { return; }
      setPostsPageNumb(0);
      fetchingAndSetUserPost();
    }, [accessToken, userData?._id, location.pathname]);

    useEffect(() => {
      if (postsPageNumb === 0) { return; }

      fetchingUserPosts();
    }, [postsPageNumb]);

    useEffect(() => {
      const handleScroll = () => {
        const {scrollTop, scrollHeight, clientHeight} = ref.current;
        
        if (scrollTop + clientHeight >= scrollHeight - 1) {
          setPostsPageNumb(prev => prev + 1);
        }
      }

      ref.current.addEventListener('scroll', handleScroll);
      return () => {ref?.current?.removeEventListener('scroll', handleScroll)}
    }, []);

    return (
      <div className={classes.root} >
        {showAddNewPost && (
          <div className={classes.modal}>
            <div className={classes.modalContent}>
              <div className={classes.modalHeader}>
                <p>Add New Post</p>
                <button className={classes.modalCloseButton} onClick={closeModal}>
                  <SvgIcon iconName="icon_close_2" />
                </button>
              </div>
              <AddNewPost
                buttonText="Post"
                fetchingUserPosts={fetchingUserPostsAfterAddNewPost}
                placeholderText="Type Something!"
              />
            </div>
          </div>
        )}
  
        {postsData.map((postData, i) => (
          <UserPost
            key={i}
            postData={postData}
            postInd={i}
          />
        ))}
      </div>
    );
  }
);

export default UserPageShortPosts;