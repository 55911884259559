// components
import Skeleton from "../../../../other/Skeleton/Skeleton";

// other
import API from "../../../../../api/api";

// styles
import classes from './styles.module.css';

export default function PostImg({ imagesId }) {
  let imagesGrid = {
    2: classes.grid_2,
    3: classes.grid_3,
  };

  return (
    <div className={imagesGrid[imagesId.length]}>
      {imagesId.map((imgSrc, i) => (
        <Skeleton
          key={i}
          imgSrc={API.img(imgSrc)}
          imgAlt="post_image"
          imgClassName={classes.img}
        />
      ))}
    </div>
  );
}
