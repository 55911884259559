// libs
import { useState, useEffect } from 'react';
import {useSelector} from 'react-redux'
import { Link } from 'react-router-dom';

// components
import Skeleton from '../../../../other/Skeleton/Skeleton';

// other
import API from '../../../../../api/api';
import ROUTES from '../../../../../routes/routes';

// styles
import classes from './styles.module.css';

export default function FriendCard({ userId }) {
  const [userData, setUserData] = useState(null);
  const { accessToken } = useSelector(s => s.userAuthSlice);

  useEffect(() => {
    // API.getProfileInfo(userId)
    // .then(d => {
    //   if (d?.message === 'foundOneUser') {
    //     setUserData(d.data);
    //   }
    // });
  }, [userId]);

  const followingButton = () => {
    // API.followUser(accessToken, userId)
    // .then(d => {
    //   console.log(d);
    // });
  };

  const inviteToGame = () => {
    // TODO: invite to game
  }

  return (
    <div className={classes.root}>
      <div className={classes.profilePhotoBox}>
        <Skeleton 
          imgSrc={(userData && userData.profilePhoto) ? API.img(userData.profilePhoto) : '/default_profile_picture.png'}
          imgAlt='profile_photo'
          imgClassName={classes.profielPhoto}
        />
      </div>
      
      <div className={classes.infoBox}>
        <p className={classes.nameSurname}>{userData?.firstname} {userData?.lastname}</p>
        <Link className={classes.username} to={`${ROUTES.USER_PROFILE}/${userData?._id}`}>@{userData?.username}</Link>
      </div>

      <div className={classes.menuBox}></div>

      <div className={classes.hoveredBox}>
        <div className={classes.profileData}>
          <div className={classes.profilePhotoBox}>
          <Skeleton 
            imgSrc={(userData && userData?.profilePhoto) ? API.img(userData.profilePhoto) : '/default_profile_picture.png'}
            imgAlt='profile_photo'
            imgClassName={classes.profielPhoto}
          />
          </div>
          
          <div className={classes.infoBox}>
            <p className={classes.nameSurname}>{userData?.firstname} {userData?.lastname}</p>
            <p className={classes.username}>@{userData?.username}</p>
          </div>
        </div>

        <div className={classes.buttons}>
          <button onClick={followingButton}>Following</button>
          <button onClick={inviteToGame}>Invite to Game</button>
        </div>
      </div>
    </div>
  )
}
