// libs 
import { useState } from 'react';

// components
import SvgIcon from '../SvgIcon';

// styles
import classes from './styles.module.css';

export default function MiniMenu({ iconName, list }) {
  const [showMenu, setShowMenu] = useState(false);

  const listItemClick = (item) => {
    item.event();
    setShowMenu(false);
  }

  return (
    <div className={classes.root}>
      <button onClick={() => setShowMenu(prev => !prev)}>
        <SvgIcon iconName={iconName}/>
      </button>

      {
        showMenu &&
        <div className={ classes.menu} >
          {
            list.map((item, i) => 
              <button key={i} className={classes.item} onClick={() => listItemClick(item)}>
                <SvgIcon iconName={item.iconName}/>
                <p className={classes.title}>{item.title}</p>
              </button>
            )
          }
        </div>
      }
    </div>
  )
}
