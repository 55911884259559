// libs
import React from "react";

// components
// import AppDownloadLinks from "../../other/AppDownloadLinks";
import Skeleton from "../../other/Skeleton/Skeleton";

// images
import phone_mokup from "../../../assets/img/landingPage/phone_gameplay_mokup.png";
import phone_gameplay from "../../../assets/img/landingPage/phone_gameplay.png";

// styles
import classes from "./styles.module.css";

export default function SectionDownload() {
  return (
    <div className={classes.download}>
      <div className={`container ${classes.container}`}>
        <div className="center">
          <div>
            <h1 className={classes.headerText}>
              Download <span>Mobile App</span>
            </h1>

            <p className={classes.title}>User-Friendly Mobile Interface</p>
            <p className={classes.description}>
              Highlight a user-friendly interface designed specifically for
              mobile users, making it easy to navigate, play games, and access
              key features with a mobile-friendly layout.
            </p>

            <p className={classes.title}>Mobile-Specific Features</p>
            <p className={classes.description}>
              Highlight any unique features or functionalities that are
              specifically designed for mobile users, such as gestures,
              mobile-exclusive events, or challenges
            </p>

            <p className={classes.title}>Push Notifications</p>
            <p className={classes.description}>
              Highlight the availability of push notifications to keep users
              informed about game invites, tournament updates, or other
              important events related to their account.
            </p>

            {/* <AppDownloadLinks /> */}
          </div>
        </div>
        <div className="center">
          <Skeleton
            imgSrc={phone_mokup}
            imgAlt="phone_mokup"
            imgClassName={classes.phoneMokup}
          />
          <Skeleton
            imgSrc={phone_gameplay}
            imgAlt="phone_gameplay"
            imgClassName={classes.phoneGamplay}
          />
        </div>
      </div>
    </div>
  );
}
