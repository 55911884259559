// styles
import classes from './styles.module.css'

export default function Flag({ coutryName, className="", isShowCountryName = false }) {
  return (
    <>
      <img src={`/flags_svg/${coutryName}.svg`} alt="flag_svg" className={`${classes.root} ${className}`}/>
      {
        isShowCountryName && 
        <p style={{fontSize: '14px', color: 'var(--wh_15)'}}>{coutryName}</p>
      }
    </>
  );
}
