// libs
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components
import Player from "./Player/Player";

// others
import API from "../../../../../api/api";

// custom hooks
import useRefreshToken from "../../../../../customHooks/useRefreshToken";

// styles
import classes from "./styles.module.css";

export default function PlayingNow() {
  const { accessToken } = useSelector((s) => s.userAuthSlice);
  const [playingGames, setPlayingGames] = useState([]);
  const [refreshToken, updateRefreshToken] = useRefreshToken();

  const spectateButton = (gameId) => {
    // TODO
  };

  useEffect(() => {
    // API.getActiveGames(accessToken)
    // .then((d) => {
    //   if (d?.error?.message === "Wrong authentication token") {
    //     updateRefreshToken();
    //   }
    //   if (d?.message === "findActiveGameIds") {
    //     setPlayingGames(d.data);
    //   }
    // });
  }, [refreshToken]);

  return (
    <div className={classes.root}>
      <div className={classes.item}>
        <Player playerUserId={null} classes={classes} />
        
        <div className={classes.liveBox}>
          <div className={classes.verticalLine}>
            <div className={classes.cyrcle}></div>
          </div>
        </div>

        <Player playerUserId={null} classes={classes} />

        <div className="center">
          <button
            className={classes.spectateButton}
            onClick={() => spectateButton(null)}
          >
            Spectate
          </button>
        </div>
      </div>
      <div className={classes.item}>
        <Player playerUserId={null} classes={classes} />
        
        <div className={classes.liveBox}>
          <div className={classes.verticalLine}>
            <div className={classes.cyrcle}></div>
          </div>
        </div>

        <Player playerUserId={null} classes={classes} />

        <div className="center">
          <button
            className={classes.spectateButton}
            onClick={() => spectateButton(null)}
          >
            Spectate
          </button>
        </div>
      </div>
    </div>
  );
}
