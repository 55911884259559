// libs
import { useSelector } from "react-redux";

// components
import Skeleton from "../../other/Skeleton/Skeleton";
import SvgIcon from "../../other/SvgIcon";

// other
import API from "../../../api/api";

// styles
import classes from "./styles.module.css";

export default function PostCommentInput({
  inputText,
  setInputText,
  addCommentButton,
}) {
  const { loginedUserData } = useSelector(s => s.userDataSlice);

  return (
    <div className={classes.addCommentBox}>
      <Skeleton
        imgSrc={
          loginedUserData && loginedUserData?.profilePhoto
            ? API.img(loginedUserData.profilePhoto)
            : "/default_profile_picture.png"
        }
        imgClassName={classes.profilePhoto}
        imgAlt="profile_photo"
      />

      <input
        type="text"
        placeholder="Add Reply"
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
      />

      <button onClick={addCommentButton}>
        <SvgIcon iconName="icon_message_send" />
      </button>
    </div>
  );
}
