// libs
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// components
import CustomInput from "../../../components/custom/CustomInput";
import CustomButton from "../../../components/custom/CustomButton";

// custom hooks
import useEmail from "../../../customHooks/useEmail";

// other
import API from "../../../api/api";

// styles
import classes from "./styles.module.css";

export default function AuthForgotPassword() {
  const [email, isValidEmail, setEmail] = useEmail("");
  const [emailSended, setEmailSended] = useState(false);
  const navigate = useNavigate();

  const onClick = () => {
    // API.forgotPass(email)
    //   .then((d) => {
    //     if (d.message === 'email sending') {
          setEmailSended(true);
    //     }
    //   })
    //   .catch((e) => console.log("error"));
  };

  const cancelOnClick = () => {
    navigate(-1);
  };

  return (
    <div>
      {emailSended ? (
        <h1>Check your email.</h1>
      ) : (
        <>
          <h1 className="authHeaderText2">Forgot Password?</h1>
          <p className="authHeaderDescription">
            Please, enter your email address
          </p>

          <CustomInput
            hasError={!isValidEmail}
            value={email}
            setValue={setEmail}
            placeholder="Email"
            errorMessage="Invalid email(example@gmail.com)"
          />

          <CustomButton
            buttonText="Reset Password"
            onClick={onClick}
            className={classes.btn}
            isDisabled={!isValidEmail}
          />

          <button onClick={cancelOnClick} className={classes.cancelBtn}>
            Cancel
          </button>
        </>
      )}
    </div>
  );
}
