// libs
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { setFullData } from "../../../store/slices/userAuthSlice";

// components
import CustomInput from "../../../components/custom/CustomInput";
import CustomButton from "../../../components/custom/CustomButton";
import AuthLoginWith from "../../../components/other/AuthLoginWith";

// custom hooks
import usePassword from "../../../customHooks/usePassword";
import useEmail from "../../../customHooks/useEmail";

// other
import ROUTES from "../../../routes/routes";
// import API from "../../../api/api";

// styles
import classes from "./styles.module.css";

export default function AuthSignIn() {
  const [userNameOrEmail, isValidEmail, setUserNameOrEmail] = useEmail("");
  const [password, isValidPass, setPassword] = usePassword("");
  const [backendMessage, setBackendMessage] = useState("");
  const navigate = useNavigate();

  // const dispatch = useDispatch();

  const signUpOnClick = () => {
    // API.login(userNameOrEmail, password).then((d) => {
      // if (d?.error) {
        // setBackendMessage(d.error.message);
      // } else {
        // setBackendMessage("");
        // dispatch(setFullData(d.token));
        navigate(ROUTES.USER);
      // }
    // });
  };

  return (
    <div>
      <h1 className="authHeaderText">Sign In</h1>
      <CustomInput
        value={userNameOrEmail}
        setValue={setUserNameOrEmail}
        placeholder="Email"
        errorMessage="The email you entered is incorrect"
        hasError={!isValidEmail}
      />

      <CustomInput
        inputType="password"
        value={password}
        setValue={setPassword}
        placeholder="Password"
        errorMessage="The password you entered is incorrect"
        hasError={!isValidPass}
      />

      <CustomButton
        buttonText="Sign in"
        onClick={signUpOnClick}
        className={classes.btn}
        isDisabled={!(isValidPass && isValidEmail)}
      />

      <p className={classes.backendMessage}>{backendMessage}</p>

      <Link to={ROUTES.FORGOT_PASSWORD} className={classes.forgotPassword}>
        Forgot Password?
      </Link>

      <AuthLoginWith className={classes.loginWith} />
    </div>
  );
}
