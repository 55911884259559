// libs
import { useEffect, useRef } from "react";

// styles
import classes from "./styles.module.css";

export default function RenderMoves({
  movesCount,
  showingSteps,
  showingStepsIndex,
}) {
  const movesBottomDivRef = useRef(null);

  const convertIndexToChar = index => ["a", "b", "c", "d", "e", "f", "g", "h"][index];

  useEffect(() => {
    movesBottomDivRef.current.scrollTop = movesBottomDivRef.current.scrollHeight;
  }, [showingStepsIndex]);

  return (
    <div className={classes.showMovesBox} ref={movesBottomDivRef}>
      <div className={classes.lineHeader}>
        <p>Steps</p>
        <p>White</p>
        <p>Black</p>
      </div>

      <div className={classes.movesBox}>
        <div className={classes.movesCountBox}>
          {movesCount.map((number, i) => (
            <div key={i} className={classes.lineElem}>
              {number + 1}
            </div>
          ))}
        </div>
        <div className={classes.moves}>
          {showingSteps.map((moveInfo, i) => (
            <div
              key={i}
              className={`${classes.lineElem} ${
                showingStepsIndex === i && classes.lineElemSelected
              }`}
            >
              {convertIndexToChar(moveInfo.to.horizontally)}
              {8 - moveInfo.to.vertically}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
