// components
import SvgIcon from "../../../../other/SvgIcon";

// styles
import classes from "./styles.module.css";

export default function Reaction({ reactionIcon }) {
  const bgColorsForIcon = {
    icon_noor: classes.noor,
    figure_king_white: classes.king,
    figure_queen_white: classes.queen,
    figure_rook_white: classes.rook,
    figure_bishop_white: classes.bishop,
    figure_pawn_white: classes.pawn,
  };

  return (
    <div className={classes.root + " " + bgColorsForIcon[reactionIcon]}>
      <SvgIcon
        width={15}
        height={15}
        iconName={reactionIcon}
      />
    </div>
  );
}
