// libs
import { useLocation} from "react-router-dom";

// components
import SideBarItem from "./widgets/SideBarItem";
import UserProfile from "./widgets/UserProfile/UserProfile";

// other
import ROUTES from "../../../routes/routes";

// styles
import classes from "./styles.module.css";

export default function UserLeftSideBar() {
  const pathname = useLocation().pathname;
  
  const team = [
    {name: 'Silva Ivanian', profession: 'Product Designer', linkedIn: 'https://www.linkedin.com/in/silva-ivanian-95120b181/',},
    {name: 'Sasha Manukyan', profession: 'Technical Lead', linkedIn: 'https://www.linkedin.com/in/sasha-manukyan/',},
    {name: 'Taron Gasoyan', profession: 'AI', linkedIn: 'https://www.linkedin.com/in/taron-gasoyan/',},
    {name: 'Grigor Sarukhanyan', profession: 'Backend', linkedIn: 'https://www.linkedin.com/in/grigor-sarukhanyan-143157222/',},
    {name: 'Suren Khechoyan', profession: 'Frontend', linkedIn: 'https://www.linkedin.com/in/khe4oyan/',},
  ];

  const sections = [
    { title: "Home", svg: "icon_home", route: ROUTES.USER_HOME, isDisabled: false},
    { title: "Play Chess", svg: "icon_figures", route: ROUTES.USER_PLAY, isDisabled: false},
    { title: "Social Feed", svg: "icon_social_feed", route: ROUTES.USER_FEED, isDisabled: true},
    { title: "Analysis", svg: "icon_analysis", route: ROUTES.USER_ANALYSIS, isDisabled: true},
    { title: "Tournaments", svg: "icon_tournaments_2", route: ROUTES.USER_TOURNAMENTS, isDisabled: true},
    { title: "Learn", svg: "icon_learn_hat", route: ROUTES.USER_LEARN, isDisabled: true},
    { title: "Events and News", svg: "icon_news_paper", route: ROUTES.USER_EVENT_NEWS, isDisabled: true},
  ];

  return (
    <div className={classes.leftSideBar}>
      <UserProfile />
      
      <div>
        {sections.map((item, i) => (
          <SideBarItem
            key={i}
            itemData={item}
            isActiveSectionId={item.route === pathname}
            isDisabled={item.isDisabled}
          />
        ))}
      </div>
      <div className={classes.devTeam}>
        <h3>Development Team</h3>
        {
          team.map((person, i) => 
            <div key={i} className={classes.dev}>
              <a href={person.linkedIn} target="_blank">{person.name} - {person.profession}</a>
            </div>
          )
        }
      </div>
    </div>
  );
}
