// libs
import { useState } from "react";

export default function useGameType(initValue) {
  const [value, setValue] = useState(initValue);

  const setNewValue = (gameType, minutes, increment, preferredColor) => {
    const data = {
      gameTime: {
        gameTime: minutes,
        addingTime: increment,
      },
      type: gameType, // bullet, rapid, blitz
      preferredColor, // black, white, random
    };

    setValue(data);
  }

  return [value, setNewValue];
}