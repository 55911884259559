// libs
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// components
import SvgIcon from "../../other/SvgIcon";
import Skeleton from "../../other/Skeleton/Skeleton";

// other
import API from "../../../api/api";
import ROUTES from "../../../routes/routes";

// styles
import classes from "./styles.module.css";

export default function CustomSearch({
  placehoder = "Search",
}) {
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchingResult] = useState([]);

  // const {accessToken} = useSelector(s => s.userAuthSlice);
  const navigate = useNavigate();

  useEffect(() => {
    // const dataFetchTimer = setTimeout(() => {
    //   if (searchText.trim() === '') {
    //     setSearchingResult([]);
    //     return;
    //   }

    // API.userSearch(searchText, accessToken)
    // .then(d => {
    //   if (d?.message === 'findUsersData') {
    //     setSearchingResult(d.data);
    //   }
    // })
    // }, 500);

    // return () => { clearTimeout(dataFetchTimer);}
  }, [searchText]);

  const visitUserPage = (userId) => {
    setSearchText("");
    navigate(`${ROUTES.USER_PROFILE}/${userId}`);
  };

  return (
    <>
      <div className={classes.searchBox}>
        <input
          className={classes.input}
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder={placehoder}
        />

        <SvgIcon iconName="icon_search" className={classes.svgIcon} />

        {
          searchText !== '' &&
          <div className={classes.searchResult}>
            {searchResult.map((userData, i) => (
              <button className={classes.user} key={i} onClick={() => visitUserPage(userData._id)}>
                <div className={classes.userProfileImgBox}>
                  <Skeleton 
                    imgSrc={(userData?.profilePhoto ? API.img(userData.profilePhoto) : '/default_profile_picture.png')}
                    imgClassName={classes.profileImg}
                  />
                </div>
                <div className={classes.userInfo}>
                  <p className={classes.nameSurname}>{userData.firstname} {userData.lastname}</p>
                  <p className={classes.username}>@{userData.username}</p>
                </div>
              </button>
            ))}
          </div>
        }
      </div>

      {
        searchText !== '' && 
        <div className={classes.blackBg} onClick={() => {setSearchText("")}}></div>
      }
    </>
  );
}
