// styles
import classes from "./styles.module.css";

export default function CustomInputForModal({
  value,
  setValue,
  title,
  hasChangeButton = false,
  isDisabled = false,
  isLargeInput = false,
  isLargeVersion = false,
  placeholder = "",
  onClick = () => {},
}) {
  return (
    <div className={classes.root}>
      <div className={`${classes.titleBox} ${(isLargeVersion && (classes.titleBoxLarge))}`}>
        <p className={classes.title}>{title}</p>
      </div>
      <div className={classes.inputBox}>
        {isLargeInput ? (
          <textarea
            className={classes.largeInput}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          ></textarea>
        ) : (
          <input
            type="text"
            className={classes.input}
            disabled={isDisabled}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        )}
      </div>
      {hasChangeButton && (
        <div className={classes.changeButtonBox}>
          <button className={classes.changeButton} onClick={onClick}>
            Change
          </button>
        </div>
      )}
    </div>
  );
}
