// libs
import { useState } from "react";

// components
import CustomInput from "../../../components/custom/CustomInput";
import CustomButton from "../../../components/custom/CustomButton";

// styles
import classes from "./styles.module.css";

export default function AuthResetPassword() {
  const [newPass, setNewPass] = useState("");
  const [confirmNewPass, setConfirmNewPass] = useState("");

  const resetonClick = () => {
    // TODO
  };

  return (
    <div>
      <h1 className="authHeaderText2">Reset Account Password</h1>
      <p className="authHeaderDescription">Please, enter your new password</p>

      <CustomInput
        value={newPass}
        setValue={setNewPass}
        placeholder="New Password"
      />

      <CustomInput
        value={confirmNewPass}
        setValue={setConfirmNewPass}
        placeholder="Confirm Password"
      />

      <CustomButton
        buttonText="Reset Password"
        onClick={resetonClick}
        className={classes.btn}
      />
    </div>
  );
}
