// libs
import { useEffect, useState } from "react";

// components
import UserPostComment from "../../userPage/UserPostComment";
import Content from "./widgets/Content/Content";
import Skeleton from "../../other/Skeleton/Skeleton";
import UserPostSkeleton from "./widgets/UserPostSkeleton/UserPostSkeleton";

// other
import API from "../../../api/api";

// styles
import classes from "./styles.module.css";

export default function UserPost({ postData, postInd }) {
  const [authorData, setAuthorData] = useState(null);
  const [comments, setComments] = useState([]);
  const [showComments, setShowComments] = useState(false);

  useEffect(() => {
    // API.getProfileInfo(postData.author).then((d) => {
    //   setAuthorData(d.data);
    // });
    // API.getAllCommentsByPostId(postData._id).then((d) => {
    //   if (d.message === "foundComments") {
    //     setComments(d.data);
    //   }
    // });
  }, [postData._id]);

  return (
    <>
      <div className={classes.root} id={`post_uniqueId_${postInd}`}>
        <div>
          <Skeleton
            imgSrc={
              authorData && authorData.profilePhoto
                ? API.img(authorData.profilePhoto)
                : "/default_profile_picture.png"
            }
            imgAlt="profile_picture"
            imgClassName={classes.profilePicture}
          />
        </div>

        <div>
          <Content
            authorData={authorData}
            classes={classes}
            postData={postData}
            postInd={postInd}
            commentsCount={comments.length}
            setShowComments={setShowComments}
            authorUsername={"username_2"}
          />

          {showComments && (
            <UserPostComment
              comments={comments}
              setComments={setComments}
              postData={postData}
            />
          )}
        </div>
      </div>
    </>
  );
}
