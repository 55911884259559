// libs
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// components
import Reaction from "../../../Reaction/Reaction";
import ReactionsAdding from "../ReactionsAdding/ReactionsAdding";
import SvgIcon from "../../../../../../other/SvgIcon";

// other
import API from "../../../../../../../api/api";

// styles
import classes from "./styles.module.css";

export default function Reactions({ postId, likersId }) {
  const [showReactionsAdding, setShowReactionsAdding] = useState(false);
  const [likersIdList, setLikersIdList] = useState(likersId);
  const { userId, accessToken } = useSelector((s) => s.userAuthSlice);
  const [userIsAddedReaction, setUserIsAddedReaction] = useState(false);
  const reactions = [
    "icon_noor",
    "figure_queen_white",
    "figure_king_white",
    "figure_rook_white",
    "figure_bishop_white",
    "figure_pawn_white",
  ];
  
  const showedReactionsCalc = () => {
    const showedReactions = new Set();

    if (likersIdList) {
      const keys = Object.keys(likersIdList);
      let userLikedToo = false;

      for (let i = 0; i < keys.length; ++i) {
        if (keys[i] === userId) {
          userLikedToo = true;
        }
        showedReactions.add(likersIdList[keys[i]] - 1);
      }

      setUserIsAddedReaction(userLikedToo);
    }

    return showedReactions;
  }

  const [showedReactions, setShowedReactions] = useState(showedReactionsCalc);

  useEffect(() => {
    setShowedReactions(showedReactionsCalc());
  }, [likersIdList]);

  useEffect(() => {
    const mouseUpHandle = () => {
      setShowReactionsAdding(false);
    };

    document.addEventListener("mouseup", mouseUpHandle);

    return () => {
      document.removeEventListener("mousedown", mouseUpHandle);
    };
  }, []);

  const doLike = (iconIndex) => {
    // API.likePost(accessToken, postId, iconIndex + 1).then((d) => {
    //   if (d?.message === "LikePost") {
    //     setLikersIdList(d.data.likersId);
    //   }
    // });
  }

  const doUnLike = () => {
    if (userIsAddedReaction) {
      // API.unlikePost(postId, accessToken).then((d) => {
      //   if (d.message === "unLikePost") {
      //     setLikersIdList(prev => {
      //       const newPrev = {...prev};
      //       delete newPrev[userId];
      //       return newPrev;
      //     });
      //   }
      // });
    }
  };

  return (
    <div className={classes.likes}>
      {likersIdList &&
        [...showedReactions.values()].map((iconIndex, i) => (
          <Reaction key={i} reactionIcon={reactions[iconIndex]} />
        ))}

      <div className={classes.heartIconBox}>
        <button
          onMouseDown={() => setShowReactionsAdding(true)}
          onMouseUp={doUnLike}
        >
          <SvgIcon
            iconName="icon_reaction_heart"
            filled={false}
            className={
              classes.reactionHeard + " " + (userIsAddedReaction && classes.liked)
            }
          />
        </button>
        {showReactionsAdding && (
          <ReactionsAdding
            doLike={doLike}
          />
        )}
      </div>
    </div>
  );
}
