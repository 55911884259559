// components
import SvgIcon from "../../other/SvgIcon";

// styles
import classes from "./styles.module.css";

export default function SectionFuturesItem({ data }) {
  const { title, description, iconName } = data;

  return (
    <div className={classes.item}>
      <div>
        <SvgIcon iconName={iconName} className={classes.icon} />
      </div>

      <div>
        <p className={classes.title}>{title}</p>
        <p className={classes.description}>{description}</p>
      </div>

      <div className={classes.verticalLine}></div>
    </div>
  );
}
