// libs
import { useState } from 'react';

// components
import OnlineFriends from '../OnlineFriends';
import GameType from '../../../../../components/other/GameType/GameType';

// custom hooks
import useGameType from '../../../../../customHooks/useGameType';

// styles
import classes from './styles.module.css';

export default function PlayVsFriend({ socket, closeModal }) {
  const [gameStartData, setGameStartData] = useGameType(null);
  const [friendId, setFriendId] = useState('');

  const startButton = () => {
    if (
      gameStartData?.gameTime?.gameTime > -1 &&
      gameStartData?.gameTime?.addingTime > -1 &&
      gameStartData?.type &&
      gameStartData?.preferredColor &&
      friendId !== ""
    ) {
      // socket.emit("start", {
      //   ...gameStartData,
      //   friendId,
      // });
      closeModal();
    }
  }

  return (
    <div className={classes.root}>
      <p>Create Online Game</p>
      
      <OnlineFriends setFriendId={setFriendId} />
      <GameType setGameTypes={setGameStartData}/>
      <button className={classes.startButton} onClick={startButton}>Start Play</button>
    </div>
  )
}