// libs
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// components
import UserComment from "../UserComment/UserComment";
import PostCommentInput from "../PostCommentInput/PostCommentInput";

// other
import API from "../../../api/api";

// styles
import classes from "./styles.module.css";

export default function UserPostComment({ postData, comments, setComments }) {
  const { accessToken, userId } = useSelector((s) => s.userAuthSlice);
  const [inputText, setInputText] = useState("");
  const [userData, setUserData] = useState(null);

  const addCommentButton = () => {
    if (inputText.trim().length > 0) {
      // API.addComment(accessToken, postData._id, inputText).then((d) => {
      //   if (d.comment) {
      //     setComments((prevComments) => [...prevComments, d.comment]);
      //   }
      // });
    }
  };

  useEffect(() => {
    // API.getProfileInfo(userId)
    // .then(d => {
    //   if (d.message === 'foundOneUser') {
    //     setUserData(d.data);
    //   }
    // });
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.comments}>
        <UserComment />
      </div>

      <PostCommentInput 
        inputText={inputText}
        setInputText={setInputText}
        userData={userData}
        addCommentButton={addCommentButton}
      />
    </div>
  );
}
