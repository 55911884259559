// libs
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// componetns
import MiniMenu from "../../other/MiniMenu";
import Skeleton from "../../other/Skeleton/Skeleton";
import SvgIcon from "../../other/SvgIcon";

// other
import API from "../../../api/api";
import ROUTES from "../../../routes/routes";

// styles
import classes from "./styles.module.css";

export default function UserComment({ commentData }) {
  const { accessToken } = useSelector((s) => s.userAuthSlice);
  const { userId } = useSelector((s) => s.userAuthSlice);

  const [likersId, setLikersId] = useState(commentData.likersId);
  const [authorData, setAuthorData] = useState(null);
  const [isUserLikedToo, setIsUserLikedToo] = useState(() => {
    for (let i = 0; i < likersId.length; ++i) {
      if (likersId[i] === userId) {
        return true;
      }
    }

    return false;
  });

  const commentDomId = `subComment${commentData._id}`;
  const ourUserMiniMenuList = [
    {
      iconName: "icon_delete",
      title: "Delete",
      event: () => {
        API.deleteSubComment(commentData._id, accessToken).then((d) => {
          if (d?.message === "deleted") {
            document.querySelector(`#${commentDomId}`).remove();
          }
        });
      },
    },
  ];

  useEffect(() => {
    API.getProfileInfo(commentData.author).then((d) => setAuthorData(d.data));
  }, []);

  const likeButton = () => {
    if (isUserLikedToo) {
      API.unlikeSubComment(commentData._id, accessToken).then((d) => {
        if (d?.message === "unliked") {
          setLikersId((prev) => prev.filter((likerId) => likerId !== userId));
          setIsUserLikedToo(false);
        }
      });
    } else {
      API.likeSubcomment(commentData._id, accessToken).then((d) => {
        if (d?.message === "liked") {
          setLikersId((prev) => [...prev, userId]);
          setIsUserLikedToo(true);
        }
      });
    }
  };

  return (
    <div className={classes.root} id={commentDomId}>
      <div className={classes.mainContent}>
        <div>
          <Skeleton
            imgSrc={(authorData && authorData?.profilePhoto ? API.img(authorData?.profilePhoto) : '/default_profile_picture.png')}
            imgAlt="profile_photo"
            imgClassName={classes.profileImg}
          />
        </div>
        <div className={classes.content}>
          <Link className={classes.username} to={`${ROUTES.USER_PROFILE}/${authorData?._id}`}>@{authorData?.username}</Link>
          <p className={classes.commentText}>{commentData.text}</p>
          <button
            className={`${classes.likeButton} ${
              isUserLikedToo && classes.isUserLikedToo
            }`}
            onClick={likeButton}
          >
            <SvgIcon filled={false} iconName="icon_reaction_heart" />
            <p>{likersId.length}</p>
          </button>
        </div>
        <div className={classes.miniMenuBox}>
          <MiniMenu iconName="icon_seeMore" list={ourUserMiniMenuList} />
        </div>
      </div>
    </div>
  );
}
