// libs
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// components
import MiniMenu from "../../../../other/MiniMenu";
import PostImg from "../PostImg/PostImg";
import Statistics from "../Statistics/Statistics";

// other
import API from "../../../../../api/api";
import ROUTES from "../../../../../routes/routes";

export default function Content({
  classes,
  postData,
  authorData,
  postInd,
  commentsCount,
  setShowComments,
  authorUsername,
}) {
  const {
    author, // string
    createdAt, // string
    filesId, // array
    likersId, // object
    sheirersId, // array
    title, // post text
    _id, // string
  } = postData;
  const [isShowFullTitle, setIsShowFullTitle] = useState(false);
  const { userId } = useSelector((s) => s.userAuthSlice);

  const { accessToken } = useSelector((s) => s.userAuthSlice);
  const miniMenuIsOurUser = [
    {
      title: "Delete",
      iconName: "icon_delete",
      event: () => {
        API.deleteUserPost(accessToken, _id).then((d) => {
          if (d.message === "deleted") {
            document.querySelector(`#post_uniqueId_${postInd}`).remove();
          }
        });
      },
    },
  ];

  const miniMenuIsOtherUser = [
    {
      title: "Unfollow user @username_2",
      iconName: "icon_unfollow",
      event: () => {
        // API.unfollowUser(accessToken, author).then((d) => console.log(d));
      },
    },
    // {
    //   title: "Not interested in this post",
    //   iconName: "icon_notInterested",
    //   event: () => {
    //     // TODO
    //   },
    // },
    // {
    //   title: "Save this post",
    //   iconName: "icon_savePost",
    //   event: () => {
    //     // TODO
    //   },
    // },
    // {
    //   title: "Report this post",
    //   iconName: "icon_report",
    //   event: () => {
    //     // TODO
    //   },
    // },
    // {
    //   title: "Block this user",
    //   iconName: "icon_block",
    //   event: () => {
    //     // TODO
    //   },
    // },
  ];

  const dateFormat = (dateString) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(dateString);
    const result = months[date.getMonth()] + " " + date.getDate();

    return result;
  };

  const titleMaxLength = 200;
  return (
    <>
      <div className={classes.content}>
        <div className={classes.header}>
          <div className={classes.authorInfo}>
            <Link className={classes.authorName} to={`${ROUTES.USER_PROFILE}/${authorData?._id}`}>@username_2</Link>
            <p className={classes.publishDate}>{dateFormat(createdAt)}</p>
          </div>
          <div className={classes.seeMore}>
            {userId === postData.author ? (
              <MiniMenu iconName="icon_seeMore" list={miniMenuIsOurUser} />
            ) : (
              <MiniMenu iconName="icon_seeMore" list={miniMenuIsOtherUser} />
            )}
          </div>
        </div>

        <div>
          {isShowFullTitle ? (
            <p className={classes?.title}>{title}</p>
          ) : (
            <div className={classes.title}>
              {title
                .split("")
                .slice(0, titleMaxLength + 2)
                .join("")
                .trim()}
              {title.trim().length > titleMaxLength && (
                <button onClick={() => setIsShowFullTitle(true)}>
                  ..show more
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      <PostImg imagesId={filesId} />

      <Statistics
        classes={classes}
        likersId={likersId}
        sheirersId={sheirersId}
        commentCount={commentsCount}
        postId={_id}
        setShowComments={setShowComments}
      />
    </>
  );
}
