// libs
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// componetns
import MiniMenu from "../../other/MiniMenu";
import Skeleton from "../../other/Skeleton/Skeleton";
import SvgIcon from "../../other/SvgIcon";
import PostCommentInput from "../PostCommentInput/PostCommentInput";
import UserSubComment from '../UserSubComment';

// other
import API from "../../../api/api";
import ROUTES from "../../../routes/routes";

// styles
import classes from "./styles.module.css";

export default function UserComment() {
  const commentData = {
    _id: 'ok',
    author: "user112",
    text: "Comment example",
  };

  const { accessToken } = useSelector((s) => s.userAuthSlice);
  const { userId } = useSelector((s) => s.userAuthSlice);

  const [subComments, setSubComments] = useState([]);
  const [likersId, setLikersId] = useState(commentData.likersId);
  const [subCommentValue, setSubCommentValue] = useState('');
  const [isShowedReplyInput, setIsShowedReplyInput] = useState(false);
  const [authorData, setAuthorData] = useState(null);
  const [isUserLikedToo, setIsUserLikedToo] = useState(() => {
    for (let i = 0; i < likersId?.length; ++i) {
      if (likersId[i] === userId) {
        return true;
      }
    }

    return false;
  });
  const [isShowedSubcomments, setIsShowedSubComments] = useState(false);

  const commentDomId = `comment${commentData._id}`;
  const ourUserMiniMenuList = [
    {
      iconName: "icon_reload",
      title: "Add Reply",
      event: () => {
        getSubcomments();
      },
    },
    {
      iconName: "icon_delete",
      title: "Delete",
      event: () => {
        // API.deleteCommentById(commentData._id).then((d) => {
        //   if (d.message === "deleted") {
        //     document.querySelector(`#${commentDomId}`).remove();
        //   }
        // });
      },
    },
  ];
  
  const getSubcomments = () => {
    // API.getAllSubCommentsByCommentId(commentData._id, accessToken)
    // .then(d => {
    //   if (d.message === "findSubCommentsData") {
    //     setSubComments(d.data);
    //     setIsShowedSubComments(true);
    //     setIsShowedReplyInput(true); 
    //   }
    // });
  }

  const hideSubComments = () => {
    setSubComments([]);
    setIsShowedSubComments(false);
    setIsShowedReplyInput(false); 
  }

  useEffect(() => {
    // API.getProfileInfo(commentData.author).then((d) => setAuthorData(d.data));
  }, [commentData.author]);

  const likeButton = () => {
    if (isUserLikedToo) {
      // API.unlikeComment(commentData._id, accessToken).then((d) => {
      //   if (d?.message === "unliked") {
      //     setLikersId((prev) => prev.filter((likerId) => likerId !== userId));
      //     setIsUserLikedToo(false);
      //   }
      // });
    } else {
      // API.commentLike(commentData._id, accessToken).then((d) => {
      //   if (d?.message === "liked") {
      //     setLikersId((prev) => [...prev, userId]);
      //     setIsUserLikedToo(true);
      //   }
      // });
    }
  };

  const addSubComment = () => {
    // API.subcommentCreate(commentData._id, subCommentValue, accessToken)
    // .then(d => {
    //   if (d?.subComment) {
    //     setSubComments(prev => [...prev, d.subComment]);
    //     setSubCommentValue("");
    //   }
    // });
  }


  return (
    <div className={classes.root} id={commentDomId}>
      <div className={classes.mainContent}>
        <div>
          <Skeleton
            imgSrc={(authorData && authorData?.profilePhoto ? API.img(authorData?.profilePhoto) : '/default_profile_picture.png')}
            imgAlt="profile_photo"
            imgClassName={classes.profileImg}
          />
        </div>
        <div className={classes.content}>
          <Link className={classes.username} to={`${ROUTES.USER_PROFILE}/${authorData?._id}`}>@username_3</Link>
          <p className={classes.commentText}>{commentData.text}</p>
          <button
            className={`${classes.likeButton} ${
              isUserLikedToo && classes.isUserLikedToo
            }`}
            onClick={likeButton}
          >
            <SvgIcon filled={false} iconName="icon_reaction_heart" />
            <p>{likersId?.length}</p>
          </button>
        </div>
        <div className={classes.miniMenuBox}>
          <MiniMenu iconName="icon_seeMore" list={ourUserMiniMenuList} />
        </div>
      </div>
      
      <div>
        <div className={classes.subcomments}>
          {
            isShowedSubcomments &&
            subComments.map((subCommentData, i) => 
            <UserSubComment 
              key={i}
              commentData={subCommentData}
            />
            )
          }
        </div>
        {
          isShowedSubcomments ?
          <button onClick={hideSubComments}>hide replies</button> :
          <button onClick={getSubcomments}>show replies</button> 
        }
        {
          isShowedReplyInput &&
          <PostCommentInput 
            inputText={subCommentValue} 
            setInputText={setSubCommentValue}
            addCommentButton={addSubComment}
          />
        }
      </div>
    </div>
  );
}
