// libs
import { useState } from "react";

// components
import AuthLoginWith from "../../../components/other/AuthLoginWith";
import CustomInput from "../../../components/custom/CustomInput";
import CustomToggle from "../../../components/custom/CustomToggle";
import CustomButton from "../../../components/custom/CustomButton";
import ChangeLevel from "./widgets/ChangeLevel/ChangeLevel";

// customHooks
import useEmail from "../../../customHooks/useEmail";

// styles
import classes from "./styles.module.css";
import usePassword from "../../../customHooks/usePassword";

export default function AuthCreateAccount() {
  const [email, emailIsValid, setEmail] = useEmail("");
  const [pass, passIsValid, setPass] = usePassword("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const showChangeLevel = () => {
    if (acceptTerms === false || !emailIsValid || !passIsValid) return;
    setShowModal(true);
  };

  return (
    <div>
      <h1 className="authHeaderText">Create Account</h1>

      <CustomInput
        value={email}
        setValue={setEmail}
        placeholder="Email"
        errorMessage="Enter your email(example@gmail.com)"
        hasError={!emailIsValid}
      />

      <CustomInput
        inputType="password"
        value={pass}
        setValue={setPass}
        placeholder="Password"
        errorMessage="Password is not valid"
        hasError={!passIsValid}
      />

      <CustomToggle
        value={acceptTerms}
        setValue={setAcceptTerms}
        label="I accept Terms of Services"
        className={classes.toggle}
      />

      <CustomButton
        className={classes.btn}
        onClick={showChangeLevel}
        buttonText="Sign up"
      />

      <AuthLoginWith className={classes.loginWith} routeToSignIn={true} />

      {showModal && <ChangeLevel data={{email, pass}}/>}
    </div>
  );
}
