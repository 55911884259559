// components
import Header from "../../../layout/Header";
import SectionWelcome from "../../../components/sections/SectionWelcome";
import SectionAbout from "../../../components/sections/SectionAbout";
import SectionChessLovers from "../../../components/sections/SectionChessLovers";
import SectionFutures from '../../../components/sections/SectionFutures';
// import SectionVR from '../../../components/sections/SectionVR';
import SectionDownload from '../../../components/sections/SectionDownload';
// import SectionAI from '../../../components/sections/SectionAI/SectionAI';
// import SectionStatistics from '../../../components/sections/SectionStatistics';
import SectionQuestions from '../../../components/sections/SectionQuestions';
import Footer from "../../../layout/Footer";

export default function Home() {
  return (
    <div>
      <Header />
      <SectionWelcome />
      <SectionAbout />
      <SectionFutures />
      <SectionChessLovers />
      {/* <SectionVR /> */}
      <SectionDownload />
      {/* <SectionAI /> */}
      {/* <SectionStatistics /> */}
      <SectionQuestions />
      <Footer />
    </div>
  );
}
