// libs
// import { useState } from "react";

// components
import Skeleton from "../../../../../components/other/Skeleton/Skeleton";
// import Badge from "../../../../../components/other/Badge/Badge";

// other
import API from "../../../../../api/api";

// styles
import classes from "./styles.module.css";

export default function ImagesAndBadges({ userData }) {
  // const [badges, setBadges] = useState(["badge_queen", "badge_rook", "badge_knight"]);
  // TODO: get badges data and profile images
  
  return (
    <div className={classes.imagesBox}>
      <Skeleton
        imgSrc={(userData && userData.profilePhoto ) ? API.img(userData.profilePhoto) : '/default_profile_picture.png'}
        imgAlt="profile_picture"
        imgClassName={classes.profilePicture}
      />
      
      <Skeleton
        imgSrc={((userData && userData?.backgroundPhoto) ? API.img(userData.backgroundPhoto) : '/default_profile_banner.png')}
        imgAlt="profile_banner"
        imgClassName={classes.banner}
      />

      {/* <div className={classes.badges}>
        {badges.map((badgeName, i) => (
          <Badge badgeName={badgeName} key={i} />
        ))}
      </div> */}
    </div>
  );
}
