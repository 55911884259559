// libs
import { useState } from "react";
import { useParams } from "react-router-dom";

// components
import CustomInput from "../../../components/custom/CustomInput";
import CustomButton from "../../../components/custom/CustomButton";

// custom hooks
import usePassword from "../../../customHooks/usePassword";

// other
import API from "../../../api/api";

// styles
import classes from "./styles.module.css";

export default function AuthCreateNewPassword() {
  const [pass, passIsValid, setPass] = usePassword("");
  const [passRep, setPassRep] = useState("");
  const { token } = useParams();

  const onClick = () => {
    if (passIsValid && pass === passRep) {
      API.forgotPassNewPass(token, pass).then((d) => {
        // TODO
      });
    }
  };

  return (
    <div>
      <h1 className="authHeaderText2">Create New Password</h1>
      <p className="authHeaderDescription">Please, enter new password</p>

      <CustomInput
        hasError={!passIsValid}
        value={pass}
        setValue={setPass}
        placeholder="new password"
        errorMessage="Invalid password"
      />

      <CustomInput
        hasError={pass !== passRep}
        value={passRep}
        setValue={setPassRep}
        placeholder="repeat new password"
        errorMessage="Repeat password is not equal for password"
      />

      <CustomButton
        buttonText="Save"
        onClick={onClick}
        className={classes.btn}
        isDisabled={!(passIsValid && pass === passRep)}
      />
    </div>
  );
}
