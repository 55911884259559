// libs
import { useEffect, useState } from "react";

// components
import Skeleton from "../Skeleton/Skeleton";
import InviteFriendModal from "./widgets/InviteFriendModal";

// other
import API from "../../../api/api";

// styles
import classes from "./styles.module.css";

export default function OnlineFriends({
  containerWidthClassName,
  accessToken,
}) {
  const [onlineFriends, setOnlineFriends] = useState([]);
  const [isShowInviteModal, setIsShowInviteModal] = useState(false);

  useEffect(() => {
    const intervalMinutes = 1;

    const updateOnlineList = () => {
      // API.getOnlineFriendsList(accessToken).then((d) => {
      //   if (d?.message === "find") {
      //     setOnlineFriends(d.data);
      //   }
      // });
    };

    updateOnlineList();
    const interval = setInterval(updateOnlineList, intervalMinutes * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const inviteToGame = () => {
    setIsShowInviteModal(true);
  };

  return (
    <div className={classes.root}>
      <div className={containerWidthClassName + " " + classes.container}>
        {onlineFriends.map((item, i) => (
          <div key={i} className={classes.friend}>
            <div className={classes.friendProfileImgBox}>
              <Skeleton
                imgSrc={
                  item && item.profilePhoto
                    ? API.img(item.profilePhoto)
                    : "/default_profile_picture.png"
                }
                imgAlt="friend_profile_img"
                imgClassName={classes.friendProfileImg}
              />
            </div>
            <p className={classes.friendUsername}>@{item.username}</p>

            <button className={classes.title} onClick={inviteToGame}>
              invite to game
            </button>
          </div>
        ))}

        {isShowInviteModal && <InviteFriendModal closeModal={() => setIsShowInviteModal(false)}/>}
      </div>
    </div>
  );
}
