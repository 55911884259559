// components
// import Skeleton from "../Skeleton/Skeleton";

// styles
import classes from "./styles.module.css";

export default function Opponent({ userId }) {
  return (
    <div className={classes.root}>
      {/* <Skeleton
        imgSrc={img}
        imgAlt="profile_image"
        imgClassName={classes.img}
      /> */}
      {/* <p className={classes.username}>{username}</p> */}
    </div>
  );
}
