/* eslint-disable react-hooks/exhaustive-deps */
// libs
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// components
import UserPageEditModal from "../../../components/userPage/UserPageEditModal";
import UserButtons from "./widgets/UserButtons";
import OtherPageButtons from "../UserProfile/widgets/OtherPageButtons";
import ImagesAndBadges from "./widgets/ImagesAndBadges/ImagesAndBadges";
import ProfileInfo from "./widgets/ProfileInfo/ProfileInfo";
import UserProfileContent from "../../../components/userPage/UserProfileContent/UserProfileContent";

// other
import API from "../../../api/api";

// styles
import classes from "./styles.module.css";

export default function UserProfile() {
  const [activeTab, setActiveTab] = useState(0);
  const [profileEditModal, setProfileEditModal] = useState(false);
  const [showAddNewPost, setShowAddNewPost] = useState(false);
  const [userData, setUserData] = useState(null);
  
  const scrollableRef = useRef(null);
  const params = useParams();
  const loginedUserData = useSelector(s => s.userDataSlice.loginedUserData);
  const loginedUserId = useSelector(s => s.userAuthSlice.userId);
  const accessToken = useSelector(s => s.userAuthSlice.accessToken);
  
  const userId = params.userId;
  const [isFollowed, setIsFollowed] = useState(false);

  // logined user page header buttons
  const loginedUserPageButtons = {
    newPostButton() {
      setShowAddNewPost(true);
    },
    editProfileButton() {
      setProfileEditModal(true);
    },
  };

  const otherUserPageButtons = {
    followButton() {
      // if (isFollowed) {
      //   API.unfollowUser(accessToken, userId)
      //   .then(d => {
      //     if (d.message === 'unllow') {
      //       setIsFollowed(false);
      //     }
      //   });
      // } else {
      //   API.followUser(accessToken, userId)
      //   .then(d => {
      //     if (d?.message === 'following') {
      //       setIsFollowed(true);
      //     }
      //   });
      // }
    },
    inviteToGameButton() {
      // TODO
    },
    messageButton() {
      // TODO
    },
    seeMoreButton() {
      // TODO
    },
  };

  useEffect(() => {
    const isLoginUserFollowed = loginedUserData?.following_id?.includes(userId);
    setIsFollowed(isLoginUserFollowed);
  }, [loginedUserData]);

  useEffect(() => {
    // API.getProfileInfo(userId)
    // .then((d) => {
    //   if(d?.message === "foundOneUser"){
    //     setUserData(d.data);
    //   }
    // });
  }, [userId]);

  const tabsName = [
    "Posts",
    "Played Games",
    "Tournaments",
    "Friends",
    "Game Events",
  ];

  return (
    <div className={classes.root} ref={scrollableRef}>
      <ImagesAndBadges userData={userData} />
      <div className={classes.buttons}>
        <UserButtons
          classes={classes}
          newPostButton={loginedUserPageButtons.newPostButton}
          editProfileButton={loginedUserPageButtons.editProfileButton}
        />
      </div>
      
      <ProfileInfo userData={userData} />

      <div className={classes.tabs}>
        {tabsName.map((item, i) => (
          <button
            key={i}
            className={`${classes.tab} ${activeTab === i && classes.tabActive}`}
            onClick={() => setActiveTab(i)}
          >
            {item}
          </button>
        ))}
      </div>

      <UserProfileContent
        userData={userData}
        activeTab={activeTab}
        showAddNewPost={showAddNewPost}
        setShowAddNewPost={setShowAddNewPost}
        ref={scrollableRef}
      />

      {profileEditModal && (
        <UserPageEditModal
          modalCloseButton={() => setProfileEditModal(false)}
          profileInfo={userData}
        />
      )}
    </div>
  );
}
