// libs
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components
import FriendCard from "./widgets/FriendCard/FriendCard";

// other
// import API from "../../../api/api";

// styles
import classes from "./styles.module.css";

export default function UserPageFriends({ userData }) {
  const [tabNumber, setTabNumber] = useState(0);
  const [usersList, setUsersList] = useState([]);
  const {loginedUserData} = useSelector((s) => s.userDataSlice);

  useEffect(() => {
    const setUserList = (userData) => {
      setUsersList(tabNumber ? userData?.followers_id : userData?.following_id);
    };

    const userIsLogined = loginedUserData?._id === userData?._id;
    
    if (userIsLogined) {
      setUserList(loginedUserData);
    } else {
      // API.getProfileInfo(userData?._id).then((d) => {
      //   if (d.message === "foundOneUser") {
      //     setUserList(d.data);
      //   }
      // });
    }
  }, [tabNumber]);

  return (
    <div className={classes.root}>
      <div className={classes.tab}>
        {["Followings", "Followers"].map((item, i) => (
          <button
            key={i}
            className={`${classes.tabButton} ${
              i === tabNumber && classes.tabButtonActive
            }`}
            onClick={() => setTabNumber(i)}
          >
            {item}
          </button>
        ))}
      </div>

      <div className={classes.users}>
        (friends cards)
        {usersList?.map((userId, i) => (
          <FriendCard key={i} userId={userId} />
        ))}
      </div>
    </div>
  );
}
