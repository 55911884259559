// libs
import { useState, useEffect } from "react";

// components
// import NotificationActivationLink from "../notificationsTypes/NotificationActivationLink";
// import NotificationLikeComment from '../notificationsTypes/NotificationLikeComment';
// import NotificationSubComment from '../notificationsTypes/NotificationSubComment';
// import NotificationLikePost from '../notificationsTypes/NotificationLikePost';
// import NotificationInviteGame from '../notificationsTypes/NotificationInviteGame';
// import NotificationFollow from '../notificationsTypes/NotificationFollow';
// import NotificationAddComment from '../notificationsTypes/NotificationAddComment';
// import NotificationAddSubComment from '../notificationsTypes/NotificationAddSubComment';

// other
import API from "../../../../../api/api";

// styles
import classes from './styles.module.css';

export default function NotificationItem({notification}) {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    API.getProfileInfo(notification.userId)
    .then(d => {
      if (d?.message === 'foundOneUser') {
        setUserData(d.data);
      }
    });
  }, [notification.userId]);

  const formatDate = (inputDate) => {
    const dateNow = new Date();
    const dateNotification = new Date(inputDate);

    const passedMilliseconds = dateNow.getTime() - dateNotification.getTime();
    const passedSeconds = passedMilliseconds / 1000;
    
    let result = '';
    
    {
      const secondMax = 60;
      const minuteMax = secondMax * 60;
      const hourMax = minuteMax * 24;
      const dayMax = hourMax * 7;
      const weekMax = dayMax * 4;
      const monthMax = weekMax * 12;
  
      switch(true) {
        case (passedSeconds < secondMax) : { result = `${Math.round(passedSeconds)} seconds ago`; break;}
        case (passedSeconds < minuteMax) : { result = `${Math.round(passedSeconds / secondMax)} minutes ago`; break;}
        case (passedSeconds < hourMax) : { result = `${Math.round(passedSeconds / minuteMax)} hours ago`; break;}
        case (passedSeconds < dayMax) : { result = `${Math.round(passedSeconds / hourMax)} days ago`; break;}
        case (passedSeconds < weekMax) : { result = `${Math.round(passedSeconds / dayMax)} weeks ago`; break;}
        case (passedSeconds < monthMax) : { result = `${Math.round(passedSeconds / weekMax)} months ago`; break;}
        case (passedSeconds > monthMax - 1) : { result = `${Math.round(passedSeconds / monthMax)} years ago`; break;}
        default: {result = '(none)';}
      }
    }

    return result;
  }

  return (
    <div className={`${classes.root} ${!notification.isViewed && classes.isViewed}`}>
      <img className={classes.profileImg} src={((userData && userData?.profilePhoto) ? API.img(userData.profilePhoto) : '/default_profile_picture.png')} alt="profile_img" />
      <div>
        <div className={classes.message}>
          <span>{userData?.username}</span>
          <p>
            {notification.title.split(' ').slice(2).join(' ')}
          </p>
        </div>
        <p className={classes.date}>{formatDate(notification.createdAt)}</p>
      </div>
      <div className={classes.notificationStatus}></div>
    </div>
  )
}

// {/* {notifications.map((notification, i) => (
//   <React.Fragment key={i}>
//     {notification.type === "activationLink" && <NotificationActivationLink notification={notification} />}
//     {notification.type === "addComment" && <NotificationAddComment notification={notification} />}
//     {notification.type === "likeSubComment" && <NotificationSubComment notification={notification} />}
//     {notification.type === "follow" && <NotificationFollow notification={notification} />}
//     {notification.type === "inviteGame" && <NotificationInviteGame notification={notification} />} // not use in here
//     {notification.type === "likeComment" && <NotificationLikeComment notification={notification} />}
//     {notification.type === "likePost" && <NotificationLikePost notification={notification} />}
//     {notification.type === "addSubComment" && <NotificationAddSubComment notification={notification} />}
//   </React.Fragment>
// ))} */}
