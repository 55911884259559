// libs
import React from "react";

// styles
import classes from "./styles.module.css";

export default function CustomButton({
  buttonText,
  onClick = () => {},
  className = "",
  isDisabled = false,
}) {
  return (
    <button
      disabled={isDisabled}
      className={classes.button + " " + className}
      onClick={onClick}
    >
      {buttonText}
    </button>
  );
}
