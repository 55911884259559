// libs
import React, { useState } from "react";

// components
import SvgIcon from "../../../../../components/other/SvgIcon";
import CreateGame from "../CreateGame";
import PlayVsFriend from "../PlayVsFriend";

// custom hooks
import useRefreshToken from "../../../../../customHooks/useRefreshToken";

// styles
import buttonClasses from './styles.module.css';

export default function Buttons({ classes, isSocketReady, socket }) {
  const [, updateRefreshToken] = useRefreshToken();
  const [activeModal, setActiveModal] = useState(-1);

  const buttonData = [
    {
      isDisabled: false,
      title: "Create Game",
      icon: "icon_createGame",
      clickHandle: () => {
        console.log("%cCreate Game", "color: yellow");
        // if (!isSocketReady) {
        //   console.log("- socket is not ready");
        //   updateRefreshToken();
        //   return;
        // }

        setActiveModal(0);
      },
    },

    {
      isDisabled: true,
      title: "Play Online",
      icon: "icon_play",
      clickHandle: () => {
        console.log("%c play online", "color: yellow");
        if (!isSocketReady) {
          return;
        }

        // TODO
      },
    },
    
    {
      isDisabled: false,
      title: "Play vs Friend",
      icon: "icon_vs_friend",
      clickHandle: () => {
        console.log("%cPlay vs Friend", "color: yellow");
        if (!isSocketReady) {
          return;
        }

        setActiveModal(1);
      },
    },

    {
      isDisabled: true,
      title: "Play vs Computer",
      icon: "icon_computer",
      clickHandle: () => {
        console.log("%cPlay vs Computer", "color: yellow");
        if (!isSocketReady) {
          return;
        }

        // TODO
      },
    },

    {
      isDisabled: true,
      title: "Tournaments",
      icon: "icon_tournaments",
      clickHandle: () => {
        console.log("%cTournaments", "color: yellow");
        if (!isSocketReady) {
          return;
        }

        // TODO
      },
    },

    {
      isDisabled: true,
      title: "Learn Play Chess",
      icon: "icon_learn_hat",
      clickHandle: () => {
        console.log("%cLearn Play Chess", "color: yellow");
        if (!isSocketReady) {
          return;
        }

        // TODO
      },
    },
  ];

  const Button = ({ buttonData, className, isDisabled }) => {
    return (
      <>
        {isDisabled ? (
          <button className={classes.disabledButton}>
            {buttonData.icon !== "" && <SvgIcon iconName={buttonData.icon} />}
            <p>{buttonData.title}</p>
          </button>
        ) : (
          <button onClick={buttonData.clickHandle} className={className}>
            {buttonData.icon !== "" && <SvgIcon iconName={buttonData.icon} />}
            <p>{buttonData.title}</p>
          </button>
        )}
      </>
    );
  };

  const closeModal = () => {
    setActiveModal(-1);
  }

  return (
    <div className={classes.buttons}>
      <h2 className={classes.buttonsHeaderText}>Let's Play Chess</h2>
      <div className={classes.buttonsCotainer}>
        {buttonData.map((item, i) => (
          <Button
            buttonData={item}
            key={i}
            className={i === 0 ? classes.buttonFirst : classes.buttonDefault}
            isDisabled={item.isDisabled}
          />
        ))}
      </div>

      {activeModal > -1 && (
        <div className={buttonClasses.modal}>
          <div className={buttonClasses.modalContainer}>
            <button className={buttonClasses.closeButton} onClick={closeModal}>
              <SvgIcon iconName="icon_close" width={14} height={14} />
            </button>
            {activeModal === 0 && <CreateGame socket={socket} closeModal={closeModal}/>}
            {activeModal === 1 && <PlayVsFriend socket={socket} closeModal={closeModal}/>}
          </div>
        </div>
      )}
    </div>
  );
}
