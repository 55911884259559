// libs
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// components
import SvgIcon from "../../../../other/SvgIcon";
import Skeleton from "../../../../other/Skeleton/Skeleton";

// other
import API from "../../../../../api/api";
// import ROUTES from "../../../../../routes/routes";

// slices
// import {
//   setIsInviteApplied,
//   removeGameInvite,
// } from "../../../../../store/slices/notificationSlice";

// styles
import classes from "./styles.module.css";

export default function NotificationsGameInvite() {
  const [opponentData, setOpponentData] = useState(null);
  // const {accessToken} = useSelector(s => s.userAuthSlice);
  const { gamesInvites } = useSelector((s) => s.notificationSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const inviteData = gamesInvites[0];
    if (inviteData) {
      // API.getProfileInfo(inviteData.userId).then((d) => {
      //   if (d?.message === "foundOneUser") {
      //     setOpponentData(d.data);
      //   }
      // });
    }
  }, [gamesInvites.length]);

  const acceptButton = () => {
    // API.deleteNotificationById(gamesInvites[0]._id, accessToken)
    // .then(d => {
    //   if (d?.message === 'deleted') {
    //     navigate(ROUTES.USER_PLAY);
    //     dispatch(setIsInviteApplied(true));
    //   }
    // });
  };
  const rejectButton = () => {
    // API.deleteNotificationById(gamesInvites[0]._id, accessToken)
    // .then(d => {
    //   if (d?.message === 'deleted') {
    //     dispatch(removeGameInvite(0));
    //   }
    // });
  };

  return (
    <>
      {gamesInvites.length > 0 && (
        <div className={classes.inviteGame}>
          <button onClick={rejectButton} className={classes.closeButton}>
            <SvgIcon iconName="icon_close" width={14} height={14} />
          </button>

          <div className={classes.leftVerticalLine}></div>
          <div className={classes.content}>
            <div className={classes.profileInfo}>
              <Skeleton
                imgSrc={
                  opponentData && opponentData?.profilePhoto
                    ? API.img(opponentData.profilePhoto)
                    : "/default_profile_picture.png"
                }
                imgClassName={classes.opponentImg}
                imgAlt="opponent_image"
              />
              <div className={classes.textInfo}>
                <p className={classes.username}>@{opponentData?.username}</p>
                <p className={classes.invitedText}>Invited you to game</p>
              </div>
            </div>
            <div className={classes.buttons}>
              <button onClick={acceptButton} className={classes.accept}>
                Accept
              </button>
              <button onClick={rejectButton} className={classes.reject}>
                Reject
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
