// libs
import { useState } from "react"

export default function useEmail(initEmail) {
  const [email, setEmail] = useState(initEmail);
  const [isValid, setIsValid] = useState(false);

  const validateEmail = (inputMail) => {
    setEmail(inputMail);

    if (inputMail.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }

  return [ email, isValid,  validateEmail];
}
