// libs
import { useState } from "react";

export default function Skeleton({ imgSrc, imgClassName = "", imgAlt = "" }) {
  const [imgIsLoaded, setImgIsLoaded] = useState(false);
  
  const handleImageLoad = () => {
    setImgIsLoaded(true);
  };

  return (
    <>
      {imgIsLoaded === false && (
        <div className={"skeleton " + imgClassName}></div>
      )}
      <img
        src={imgSrc}
        className={imgClassName}
        alt={imgAlt}
        onLoad={handleImageLoad}
        style={{ display: imgIsLoaded ? "block" : "none" }}
      />
    </>
  );
}
