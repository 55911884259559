// libs
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components
import OnlineFriendsOpponent from '../OnlineFriendsOpponent';

// other
import API from "../../../../../api/api";

// styles
import classes from "./styles.module.css";

export default function OnlineFriends({ setFriendId }) {
  const [searchedResult, setSearchedResult] = useState([]);
  const [searchingText, setSearchingText] = useState("");
  const [lastPlayedFiveOpponents, setLastPlayedFiveOpponents] = useState([]);
  const [isRandomSelected, setIsRandomSelected] = useState(false);
  const { accessToken } = useSelector((s) => s.userAuthSlice);

  useEffect(() => {
    const timer = setTimeout(() => {
      const searchingUser = searchingText.trim();
      if (searchingUser === "") {
        setSearchedResult([]);
        return;
      }
      API.userSearch(searchingUser, accessToken).then((d) => {
        if (d?.message === "findUsersData") {
          setSearchedResult(d.data);
        }
      });

      return () => clearTimeout(timer);
    }, 500);
  }, [searchingText]);

  useEffect(() => {
    API.lastFivePlayers(accessToken).then((d) => {
      if (d?.message === "findMy5Opponent") {
        setLastPlayedFiveOpponents(d.data);
      }
    });
  }, []);

  const selectUser = (userId) => {
    setSearchedResult([]);
    setFriendId(userId);
  };

  return (
    <div className={classes.root}>
      <div className={classes.searchBox}>
        <input
          type="text"
          placeholder="Search by name or username"
          value={searchingText}
          onChange={(e) => setSearchingText(e.target.value)}
          className={classes.searchingText}
        />

        {searchingText.trim() !== "" && searchedResult.length > 0 && (
          <div className={classes.searchedResult}>
            {searchedResult.map((userData, i) => (
              <div
                key={i}
                className={classes.searchedUser}
                onClick={() => {
                  selectUser(userData._id);
                }}
              >
                @{userData.username}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={classes.friends}>
        {lastPlayedFiveOpponents.map((opponentId, i) => (
          <OnlineFriendsOpponent 
            key={i}
            opponentId={opponentId}
            setFriendId={setFriendId}
          />
        ))}
      </div>
      <div className={classes.checkboxContainer}>
        <input
          type="checkbox"
          id="checkbox"
          checked={isRandomSelected}
          onChange={(e) => setIsRandomSelected(e.target.checked)}
        />
        <label htmlFor="checkbox">Play with random opponent</label>
      </div>
    </div>
  );
}
