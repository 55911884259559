// libs
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// components
import Flag from "../../../../other/Flag";
import Skeleton from "../../../../other/Skeleton/Skeleton";
import Badge from "../../../../other/Badge/Badge";
import UserProfileSkeleton from "./widgets/UserProfileSkeleton";

// other
import ROUTES from "../../../../../routes/routes";
import API from "../../../../../api/api";

// styles
import classes from "./styles.module.css";

export default function UserProfile() {
  const scores = [
    {
      title: "Played Games",
      score: 0,
    },
    {
      title: "Victories",
      score: 0,
    },
  ];

  const { loginedUserData } = useSelector((s) => s.userDataSlice);

  return (
    <div className={classes.root}>
      <Link
        className={classes.link}
        to={`${ROUTES.USER_PROFILE}/${loginedUserData?._id}`}
      >
        <div className={classes.header + " " + classes.rootPadding}>
          <div className={classes.imgBox}>
            <Skeleton
              imgSrc={
                loginedUserData && loginedUserData.profilePhoto
                  ? API.img(loginedUserData?.profilePhoto)
                  : "/default_profile_picture.png"
              }
              imgAlt="profile_picture"
              imgClassName={classes.img}
            />
          </div>
          <div className={classes.profileInfoBox}>
            <h1 className={classes.nameSurname}>
              Name Surname
            </h1>
            <p className={classes.username}>@username</p>
          </div>
          <div className={classes.flagBox}>
            <Flag coutryName="Armenia" />
          </div>
        </div>
      </Link>

      <div className={`${classes.rootPadding} ${classes.ratingBox}`}>
        <div className={classes.rating}>
          <p className={classes.ratingType}>
            Bullet <span>100</span>
          </p>
          <p className={classes.ratingType}>
            Rapid <span>200</span>
          </p>
          <p className={classes.ratingType}>
            Blits <span>500</span>
          </p>
        </div>
      </div>

      {scores.map(({ title, score }, i) => (
        <div key={i} className={classes.scoreBox + " " + classes.rootPadding}>
          <p className={classes.title}>{title}</p>
          <p className={classes.score}>{score}</p>
        </div>
      ))}

      <div className={classes.footer + " " + classes.rootPadding}>
        <div className={classes.icons}>
          <Badge badgeName="badge_queen" />
          <Badge badgeName="badge_rook" />
          <Badge badgeName="badge_knight" />
        </div>
        <Link
          to={`${ROUTES.USER_PROFILE}/${loginedUserData?._id}`}
          className={classes.myProfileLink}
        >
          My Profile
        </Link>
      </div>
    </div>
  );
}
