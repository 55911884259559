/* eslint-disable react-hooks/exhaustive-deps */
// libs
import React from "react";
import { Outlet } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

// components
import SvgIcon from "../../../components/other/SvgIcon";

// images
import rookImg from "../../../assets/img/landingPage/rook.webp";

// other
import ROUTES from "../../../routes/routes";

// styles
import classes from "./styles.module.css";

export default function Auth() {
  const navigate = useNavigate();
  const params = useLocation();

  React.useEffect(() => {
    const path = params.pathname;

    if (path === ROUTES.AUTH || path === ROUTES.AUTH + "/") {
      navigate(ROUTES.CREATE_ACCOUNT);
    }
  }, []);

  const closeButton = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <div className={classes.auth}>
      <div
        className={classes.img}
        style={{ backgroundImage: `url(${rookImg})` }}
      ></div>
      <div className={classes.outlet + " center"}>
        <div className={classes.container}>
          {<Outlet />}
          <button onClick={closeButton} className={classes.closeButton}>
            <SvgIcon iconName="icon_close" />
          </button>
        </div>
      </div>
    </div>
  );
}
