// components
import Reaction from "../../../Reaction";

// styles
import classes from "./styles.module.css";

export default function ReactionsAdding({
  doLike
}) {
  const reactions = [
    "icon_noor",
    "figure_queen_white",
    "figure_king_white",
    "figure_rook_white",
    "figure_bishop_white",
    "figure_pawn_white",
  ];

  return (
    <div className={classes.likes}>
      {reactions.map((_, iconIndex) => (
        <button
          key={iconIndex}
          onMouseUp={() => doLike(iconIndex)}
          className={classes.reactionButton}
        >
          <Reaction reactionIcon={reactions[iconIndex]} />
        </button>
      ))}
    </div>
  );
}
