// libs
import { useState, useEffect } from "react";

// compoents
import SvgIcon from "../SvgIcon";

// styles
import classes from "./styles.module.css";

export default function GameType({ setGameTypes }) {
  const [gameType, setGameType] = useState("");
  const [gameMinutes, setGameMinutes] = useState(0);
  const [incrementSeconds, setIncrementSeconds] = useState(0);
  const [color, setColor] = useState("");

  useEffect(() => {
    setGameTypes(
      gameType,
      gameMinutes * 60 * 1000,
      incrementSeconds * 1000,
      color
    );
  }, [gameType, gameMinutes, incrementSeconds, color]);

  const gameDelays = [
    {
      gameType: "Bullet",
      variants: [
        { delay: 1, increment: 0 },
        { delay: 2, increment: 1 },
        { delay: 5, increment: 0 },
      ],
    },
    {
      gameType: "Blitz",
      variants: [
        { delay: 3, increment: 0 },
        { delay: 3, increment: 2 },
        { delay: 15, increment: 10 },
      ],
    },
    {
      gameType: "Rapid",
      variants: [
        { delay: 10, increment: 0 },
        { delay: 10, increment: 5 },
        { delay: 15, increment: 10 },
      ],
    },
  ];

  const gameColors = [
    {color: "white", iconName: 'icon_select_king_white'},
    {color: "random", iconName: 'icon_select_king_random'},
    {color: "black", iconName: 'icon_select_king_black'},
  ];

  const setDelays = (minutes, seconds) => {
    setGameMinutes(minutes);
    setIncrementSeconds(seconds);
  };

  return (
    <div className={classes.root}>
      <div className={classes.gameTypes}>
        {gameDelays.map((gameTypeData, i) => (
          <div
            onClick={() => setGameType(gameTypeData.gameType.toLowerCase())}
            key={i}
          >
            <p className={classes.gameTypeName}>{gameTypeData.gameType}</p>
            <div className={classes.gameTypeButtons}>
              {gameTypeData.variants.map((variant, j) => (
                <button
                  className={`${classes.gameDelay} ${((gameType.toLowerCase() === gameTypeData.gameType.toLowerCase()) && (gameMinutes === variant.delay) && (incrementSeconds === variant.increment)) && classes.gameDelaySelected}`}
                  onClick={() => {
                    setDelays(variant.delay, variant.increment);
                  }}
                  key={j}
                >
                  {variant.delay}+{variant.increment}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className={classes.colorChoose}>
        <p className={classes.colorChooseText}>Choose side</p>
        <div className={classes.colorsButtons}>
          {gameColors.map((colorData, i) =>
            <div key={i} className={`${classes.color} ${color === colorData.color && classes.colorSelected}`} onClick={() => setColor(colorData.color)}>
              <SvgIcon iconName={colorData.iconName} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
