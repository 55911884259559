// libs
import React from "react";
import { Link } from "react-router-dom";

// components
// import AppDownloadLinks from "../AppDownloadLinks/AppDownloadLinks";
import SvgIcon from "../../components/other/SvgIcon";

// styles
import classes from "./styles.module.css";

export default function Footer() {
  return (
    <footer className={classes.footer}>
      <div className="container">
        <header className={`${classes.grid} ${classes.header}`}>
          <p>Product</p>
          <p>Tools</p>
          <p>Policies</p>
          <p className={classes.right}>Stay in Touch</p>
        </header>

        <div className={`${classes.grid} ${classes.content}`}>
          <div className={classes.box}>
            <p className="hoveredText">About Us</p>
            <p className="hoveredText">Social Platform</p>
            <p className="hoveredText">Community</p>
            <p className="hoveredText">Chesskids</p>
            <p className="hoveredText">Support</p>
          </div>
          <div className={classes.box}>
            <p className="hoveredText">Analysis Game</p>
            <p className="hoveredText">VR/AR Game</p>
            <p className="hoveredText">Voice Commands</p>
            <p className="hoveredText">Edit Board</p>
            <p className="hoveredText">AI Opponents</p>
          </div>
          <div className={classes.box}>
            <p className="hoveredText">Privacy Policy</p>
            <p className="hoveredText">Terms of Use</p>
            <p className="hoveredText">Let's Talk</p>
            <p className="hoveredText">FAQ</p>
          </div>
          <div className={`${classes.box} ${classes.box_4}`}>
            <div className={classes.socialMediaIcons}>
              <Link to="https://picsartacademy.am/">
                <SvgIcon iconName="icon_vkontakte" className={classes.icon}/>
              </Link>
              <Link to="https://picsartacademy.am/">
                <SvgIcon iconName="icon_facebook" className={classes.icon}/>
              </Link>
              <Link to="https://picsartacademy.am/">
                <SvgIcon iconName="icon_twitter" className={classes.icon}/>
              </Link>
              <Link to="https://picsartacademy.am/">
                <SvgIcon iconName="icon_instagram" className={classes.icon}/>
              </Link>
              <Link to="https://picsartacademy.am/">
                <SvgIcon iconName="icon_youtube" className={classes.icon}/>
              </Link>
            </div>
            {/* <AppDownloadLinks 
              className={classes.appsLinks}
            /> */}
          </div>
        </div>
        <p className={classes.copyright}>All Rights Reserved. 2024</p>
      </div>
    </footer>
  );
}
