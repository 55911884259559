// libs
import { useState } from "react"
import { useSelector } from "react-redux"

// components
import CustomInputForModal from "../../../../custom/CustomInputForModal"
import CustomButton from "../../../../custom/CustomButton"

// other
import API from "../../../../../api/api"
import usePassword from "../../../../../customHooks/usePassword"

// styles
import classes from './styles.module.css';

export default function ChangePassword({ closeModal }) {
  const [oldPassword, isValidOldPass, setOldPassword] = usePassword("");
  const [newPassword, isValidNewPass, setNewPassword] = usePassword("");
  const {userId, accessToken} = useSelector(s => s.userAuthSlice);
  const [errorMessage, setErrorMessage] = useState("");

  const saveButton = () => {
    if ((isValidOldPass && isValidNewPass)) {
      // API.updateProfilePasswordLink(oldPassword, newPassword, userId, accessToken)
      // .then(d => {
      //   console.log(d);
      //   if (d.error) {
      //     if(d?.error?.message) {
      //       setErrorMessage(d.error.message);
      //     }
      //   } else if (d.message === 'updated') {
          closeModal(); 
      //   }
      // })
    }
  }

  return (
    <div>
      <CustomInputForModal 
        title="Current Password"
        value={oldPassword}
        setValue={setOldPassword}
        isLargeVersion={true}
      />

      <CustomInputForModal 
        title="New Password"
        value={newPassword}
        setValue={setNewPassword}
        isLargeVersion={true}
      />

      {
        errorMessage !== "" &&
        <p className={classes.errorMessage}>{errorMessage}</p>
      }

      <CustomButton 
        buttonText='Save'
        className={classes.button}
        onClick={saveButton}
        isDisabled={!(isValidNewPass && isValidOldPass)}
      />
    </div>
  )
}
