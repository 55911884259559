import { createSlice } from "@reduxjs/toolkit";

const chessBoardSlice = createSlice({
  name: 'chessBoardSlice',
  initialState: {
    chessBoard: initChessBoard(),
    whereCanMove: null,
    checkKingPosition: null,
    boardRotated: false,
    isGameEnd: null,
    showingSteps: [],
    showingStepsIndex: -1,
  },

  reducers: {
    setIsGameEnd(state, actions) {
      state.isGameEnd = actions.payload;
    },
    setShowingSteps(state, actions) {
      state.showingSteps = actions.payload;
      state.showingStepsIndex = state.showingSteps.length - 1;
      state.chessBoard = calculateSteps(state.showingSteps, state.showingStepsIndex);
    },
    showingStepsIndIncrement(state) {
      const newIndex = state.showingStepsIndex + 1;
      state.showingStepsIndex = newIndex < state.showingSteps.length ? newIndex : state.showingStepsIndex;
      state.chessBoard = calculateSteps(state.showingSteps, state.showingStepsIndex);
      state.whereCanMove = null;
    },
    showingStepsIndDecrement(state) {
      const newIndex = state.showingStepsIndex - 1;
      state.showingStepsIndex = newIndex > -2 ? newIndex : state.showingStepsIndex;
      state.chessBoard = calculateSteps(state.showingSteps, state.showingStepsIndex);
      state.whereCanMove = null;
    },
    setBoard(state, actions) {
      const chessBoard = actions.payload.board;
      const kingsposition = actions.payload.kingsPosition;

      const checkCheck = () => {
        let checkedKingPosition = null;
 
        if (kingsposition.black.check) {
          checkedKingPosition = {vertically: kingsposition.black.vertically, horizontally: kingsposition.black.horizontally};
        } else
        if (kingsposition.white.check) {
          checkedKingPosition = {vertically: kingsposition.white.vertically, horizontally: kingsposition.white.horizontally};
        }

        return checkedKingPosition;
      };
      
      const checkKingPosition = checkCheck();

      state.chessBoard = chessBoard;
      state.checkKingPosition = checkKingPosition;
    },
    setOnlyBoard(state, actions) {
      state.chessBoard = actions.payload;
    },
    boardRotate(state, actions) {
      state.boardRotated = actions.payload;
    },
    setWhereCanMove(state, actions) {
      state.whereCanMove = actions.payload;
    },
    resetAllChessBoard(state) {
      state.chessBoard = initChessBoard();
      state.boardRotated = false;
      state.whereCanMove = null;
      state.checkKingPosition = null;
    },
  },
});

export const {
  setBoard,
  boardRotate,
  setWhereCanMove,
  setOnlyBoard,
  resetAllChessBoard,
  setShowingSteps,
  showingStepsIndIncrement,
  showingStepsIndDecrement,
  setIsGameEnd,
} = chessBoardSlice.actions;
export default chessBoardSlice.reducer;

// =======================
// ==== FUNCTION =========
function initChessBoard() {
  const initChessBoard = new Array(8);
  for (let i = 0; i < 8; i++) {
    initChessBoard[i] = new Array(8).fill({pieces: null, color: null, isTouched: true});
  }

  const figures = [
    "rook",
    "knight",
    "bishop",
    "queen",
    "king",
    "bishop",
    "knight",
    "rook",
  ];

  const figureCreate = (color, vertically, horizontally, pieces) => {
    return {
      color,
      vertically,
      horizontally,
      pieces,
      isTouched: false,
    }
  }

  for (let i = 0; i < 8; ++i) {
    initChessBoard[1][i] = figureCreate('black', 0, i, 'pawn');
    initChessBoard[6][i] = figureCreate('white', 6, i, 'pawn');
    
    initChessBoard[7][i] = figureCreate('white', 7, i, figures[i]);
    initChessBoard[0][i] = figureCreate('black', 0, i, figures[i]);
  }

  return initChessBoard;
}

function calculateSteps(steps, showingInd) {
  const chessBoard = initChessBoard();

  for (let i = 0; i < showingInd + 1; ++i) {
    const step = steps[i];
    
    const fromI = step.from.vertically;
    const fromJ = step.from.horizontally;

    const toI = step.to.vertically;
    const toJ = step.to.horizontally;

    const moveFigure = () => {
      const currentFigure = chessBoard[fromI][fromJ];
      chessBoard[fromI][fromJ] = {pieces: null, vertically: fromI, horizontally: fromJ, color: null, isTouched: true};
      chessBoard[toI][toJ] = currentFigure;
    }
    
    const stepTypeFunctions = {
      'step': () => {
        moveFigure();
      },
      'promotion': () => {
        moveFigure();
        chessBoard[toI][toJ].pieces = step.figure;
      },
      'enPassant': () => {
        moveFigure();
        if (chessBoard[toI][toJ].color === 'white') {
          chessBoard[toI + 1][toJ].color = null;
          chessBoard[toI + 1][toJ].pieces = null;
        } else {
          chessBoard[toI - 1][toJ].color = null;
          chessBoard[toI - 1][toJ].pieces = null;
        }
      },
      'castling': () => {
        moveFigure();
        if (toJ > 4) {
          // if j > 4 : 7:7 rook move to 7:5
          const currentFigure = chessBoard[fromI][7];
          chessBoard[fromI][7] = {pieces: null, vertically: fromI, horizontally: fromJ, color: null, isTouched: true};
          chessBoard[fromI][5] = currentFigure;
        } else {
          // if j < 4 : 7:0 rook move to 7:3
          const currentFigure = chessBoard[fromI][0];
          chessBoard[fromI][0] = {pieces: null, vertically: fromI, horizontally: fromJ, color: null, isTouched: true};
          chessBoard[fromI][3] = currentFigure;
        }
      },
    }
  
    if(stepTypeFunctions[step.type]) {
      stepTypeFunctions[step.type]();
    } else {
      console.error('undefined behaviour: type is invalid');
    }
  }

  return chessBoard;
}