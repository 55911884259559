// styles
import classes from "./styles.module.css";

export default function CustomImgLoad({
  children,
  idName,
  setFiles,
  multiple = false,
}) {
  return (
    <label htmlFor={idName} className={classes.fileInput}>
      <input
        type="file"
        accept="image/*"
        multiple={multiple}
        id={idName}
        onChange={(e) => setFiles(e.target.files)}
        style={{ display: "none" }}
      />
      {children}
    </label>
  );
}
