// libs
import React from "react";

// other
import iconsData from "./icons/iconsData";

export default function SvgIcon({
  iconName,
  className = "",
  width = 24,
  height = 24,
  filled = true,
}) {
  const { viewBoxW, viewBoxH } = iconsData[iconName];
  const svg = iconsData[iconName].svg ?? iconsData[iconName];

  if (!svg) {
    throw new Error("wrong icon");
  }

  return (
    <svg
      className={`${className} center`}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={filled ? "" : "none"}
      viewBox={`0 0 ${viewBoxW ?? width} ${viewBoxH ?? height}`}
    >
      {svg}
    </svg>
  );
}
