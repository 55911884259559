// libs
import { useState } from "react";
import { Link } from "react-router-dom";

// components
import SvgIcon from "../../../../other/SvgIcon";

// other
import ROUTES from "../../../../../routes/routes";

// styles
import classes from "./styles.module.css";

export default function ProfileDropDown() {
  const [isOpened, setIsOpened] = useState(false);

  const buttonClick = () => {
    setIsOpened(prev => !prev);
  }

  const linkClick = () => {
    setIsOpened(false);
  }

  return (
    <div className={classes.root}>
      <button onClick={buttonClick}>
        <SvgIcon iconName="icon_down" />
      </button>

      {isOpened && (
        <div className={classes.linksBox}>
          {/* <Link onClick={linkClick} to={ROUTES.USER_SETTINGS} className={classes.link}>Settings</Link> */}
          {/* <Link onClick={linkClick} to={ROUTES.USER_SUPPORT} className={classes.link}>Support</Link> */}
          <Link onClick={linkClick} to={ROUTES.USER_LOGOUT} className={classes.link}>Logout</Link>
        </div>
      )}
    </div>
  );
}
