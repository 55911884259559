// libs
import React from "react";
import { Link } from "react-router-dom";
import CustomButton from "../../components/custom/CustomButton";
// import CustomDropDown from '../../components/custom/CustomDropDown'

// other
import ROUTES from "../../routes/routes";

// styles
import classes from "./styles.module.css";

export default function Header() {
  return (
    <header className={classes.header}>
      <div className={`container ${classes.headerContainer}`}>
        <p className={classes.chessLogo}>Chesslogo</p>
        <div className={classes.navigation}>
          {/* <CustomDropDown
            title="Play"
            list={["item 1", "item 2", "item 3", "item 4", "item 5"]}
          />
          <CustomDropDown title="Social" list={["(line sample)"]} />
          <CustomDropDown title="Learn" list={["(line sample)"]} />
          <CustomDropDown title="Tools" list={["(line sample)"]} />
          <CustomDropDown title="Watch" list={["(line sample)"]} />
          <CustomDropDown title="Community" list={["(line sample)"]} />
          <CustomDropDown title="News" list={["(line sample)"]} /> */}
          <Link to={ROUTES.USER_HOME}>
            <CustomButton buttonText="Sign In" className={classes.button} />
          </Link>
        </div>
      </div>
    </header>
  );
}
