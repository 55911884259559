// libs
import React from "react";

// components
import SectionFuturesItem from "../../sections/SectionFuturesItem/SectionFuturesItem";

// other
import itemsData from "./itemsData";

// styles
import classes from "./styles.module.css";

export default function SectionFutures() {
  return (
    <div className={classes.futures}>
      <div className="container">
        <h1 className={classes.headerText}>Main Features of Chesstitle</h1>
        <div className={classes.itemsBox}>
          {itemsData.map((item, i) => (
            <SectionFuturesItem key={i} data={item} />
          ))}
        </div>
      </div>
    </div>
  );
}
