import badges from './iconsType/badges';
import icons from './iconsType/icons';
import logos from './iconsType/logos';
import figures from './iconsType/figures';

const iconsData = {
  ...badges,
  ...icons,
  ...logos,
  ...figures,
};

export default iconsData;
