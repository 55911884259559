// libs
import React from "react";

// components
import FigureNotConnection from "../Figure/FigureNotConnection";

// styles
import classes from "./styles.module.css";

export default function SquareNotConnection({
  squareData,
  colorCounter,
  i,
  j,
}) {
  return (
    <div
      className={`
        ${classes.square} 
        ${colorCounter % 2 === 0 ? classes.blackSquare : classes.whiteSquare} 
      `}
    >
      {squareData?.pieces && <FigureNotConnection figureData={squareData}/>}
    </div>
  );
}
