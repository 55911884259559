// libs
import { useState } from "react";

export default function usePassword(initValue) {
  const [value, setValue] = useState(initValue);
  const [isValid, setIsValid] = useState(false);

  const valueValidate = (newValue) => {
    setValue(newValue);
    setIsValid(newValue.match(/^(?=.*[A-Za-z])(?=.*\d).{8,}$/))
  };

  return [value, isValid, valueValidate];
}
