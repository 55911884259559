const ROUTES = {
  HOME: "/",

  AUTH: "/auth",
  SIGN_IN: "/auth/sign_in",
  CREATE_ACCOUNT: "/auth/sign_up",
  FORGOT_PASSWORD: "/auth/forgor_password",
  RESET_PASSWORD: "/auth/reset_password",
  RESET_PASSWORD_NEW_PASS: "/auth/reset_password_new_pass",

  USER: "/user",
  USER_HOME: "/user/home",
  USER_PROFILE: "/user/profile",
  USER_PLAY: "/user/play",
  USER_MESSAGES: "/user/messages",
  USER_ANALYSIS: "/user/analysis",
  USER_LEARN: "/user/learn",
  USER_TOURNAMENTS: "/user/tournaments",
  USER_SETTINGS: "/user/settings",
  USER_SUPPORT: "/user/support",
  USER_EVENT_NEWS: "/user/news",
  USER_FEED: "/user/feed",
  
  USER_LOGOUT: "/logout",

  NOT_FOUND: '*',
};

export default ROUTES;
