/* eslint-disable react-hooks/exhaustive-deps */

// libs
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// other
import API from "../../../api/api";
import ROUTES from "../../../routes/routes";
import { useEffect } from "react";
import {
  setUserId,
  setAccessToken,
  setRefreshToken,
  setGameId,
} from "../../../store/slices/userAuthSlice";

export default function UserLogout() {
  const { accessToken } = useSelector((s) => s.userAuthSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("%clogoutPage", "color: orange");

    // API.logout(accessToken);
    navigate(ROUTES.SIGN_IN);

    dispatch(setUserId(null));
    dispatch(setAccessToken(null));
    dispatch(setRefreshToken(null));
    dispatch(setGameId(null));
  }, []);
}
