// libs
import { useSelector } from "react-redux";

// compnents
import CustomInputForModal from "../../../../custom/CustomInputForModal";
import CustomButton from "../../../../custom/CustomButton";

// custom hooks
import usePassword from "../../../../../customHooks/usePassword";
import useEmail from "../../../../../customHooks/useEmail";

// other
import API from "../../../../../api/api";

// styles
import classes from "./styles.module.css";

export default function ChangeEmail({ closeModal }) {
  const [password, isValidPassword, setPassword] = usePassword("");
  const [newEmail, isValidEmail, setNewEmail] = useEmail("");
  const { userId, accessToken } = useSelector(s => s.userAuthSlice);

  const saveButton = () => {
    if ((isValidPassword && isValidEmail)) {
      // API.updateProfileEmail(newEmail, password, userId, accessToken).then((d) => {
      //   if (d.message === 'updated') {
          closeModal();
      //   }
      // });
    }
  };

  return (
    <div>
      <CustomInputForModal
        value={password}
        setValue={setPassword}
        title="Your Password"
        isLargeVersion={true}
      />

      <CustomInputForModal
        value={newEmail}  
        setValue={setNewEmail}
        title="New Email"
        isLargeVersion={true}
      />

      <CustomButton
        className={classes.button}
        buttonText="Save"
        onClick={saveButton}
      />
    </div>
  );
}
