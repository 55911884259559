/* eslint-disable jsx-a11y/img-redundant-alt */
// libs
import { useState } from "react";
import { useSelector } from "react-redux";

// components
import CustomButton from "../../custom/CustomButton";
import SvgIcon from "../../other/SvgIcon";
import Skeleton from "../Skeleton/Skeleton";

// other
import API from "../../../api/api";

// classes
import classes from "./styles.module.css";

export default function AddNewPost({
  buttonText,
  className = "",
  placeholderText = "(change placeholder)",
  fetchingUserPosts = () => {},
}) {
  const { userData } = useSelector((s) => s.userDataSlice);
  const { accessToken } = useSelector((s) => s.userAuthSlice);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [inputText, setInputText] = useState("");

  const onClick = () => {
    if (
      (selectedFiles !== null && selectedFiles?.length > 0) ||
      inputText.trim().length > 0
    ) {
      // API.newPost(accessToken, selectedFiles ?? null, inputText)
      // .then((d) => {
      //   if (d?.post) {
      //     fetchingUserPosts();
      //   }
      // });
      setInputText("");
      // document.querySelector(classes.files).value = "";
      // setSelectedFiles(null);
    }
  };

  // const addVideoButton = () => {
  //   // TODO
  // };
  // const smileButton = () => {
  //   // TODO
  // };

  const activeButtonClassNameSet = () => {
    const className = classes.postActiveButton;
    if (
      inputText.trim() !== "" ||
      (selectedFiles !== null && selectedFiles?.length > 0)
    ) {
      return className;
    }
  };

  const removeImportedImgByInd = (imgIndex) => {
    setSelectedFiles(prev => {
      const newPrev = new DataTransfer();

      for (const [i, item] of Array.from(prev).entries()) {
        if (i === imgIndex) { continue; }
        newPrev.items.add(prev[i]);
      }

      return newPrev.files;
    });
  }

  return (
    <div className={`${classes.root} ${className}`}>
      <div>
        <Skeleton
          imgSrc={
            userData && userData?.profilePhoto
              ? API.img(userData?.profilePhoto)
              : "/default_profile_picture.png"
          }
          imgClassName={classes.img}
          imgAlt="profile picture"
        />
      </div>

      <div className={classes.actions}>
        <textarea
          className={classes.input}
          placeholder={placeholderText}
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
        ></textarea>

        <div className={classes.selectedPhotosBox}>
          {selectedFiles &&
            Array.from(selectedFiles).map((file, index) => (
              <div key={index} className={classes.selectedImgBox}>
                <button className={classes.removeButtonBox} onClick={() => removeImportedImgByInd(index)}>x</button>
                <img
                  src={URL.createObjectURL(file)}
                  alt='selected_image'
                  className={classes.selectedImage}
                />
              </div>
            ))}
        </div>

        <div className={classes.buttons}>
          <div className={classes.smallIcons}>
            <label htmlFor={classes.fileInput}>
              <input
                type="file"
                accept="image/*"
                multiple
                id={classes.files}
                onChange={(e) => setSelectedFiles(e.target.files)}
                style={{ display: "none" }}
              />
              <SvgIcon iconName="icon_addImage" className={classes.smallIcon} />
            </label>
            {/* <button onClick={addVideoButton}>
              <SvgIcon
                iconName="icon_video"
                className={classes.smallIcon + " " + classes.smallIcon_video}
              />
            </button> */}
            {/* <button onClick={smileButton}>
              <SvgIcon iconName="icon_smile" className={classes.smallIcon} />
            </button> */}
          </div>
          <CustomButton
            className={`${classes.postButton} ${activeButtonClassNameSet()}`}
            buttonText={buttonText}
            onClick={onClick}
          />
        </div>
      </div>
    </div>
  );
}
