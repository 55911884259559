/* eslint-disable react-hooks/exhaustive-deps */

// libs
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { 
  useSelector, 
  // useDispatch
} from "react-redux";

// components
import SvgIcon from "../../other/SvgIcon";
import CustomSearch from "../../custom/CustomSearch/CustomSearch";
import ProfileDropDown from "./widgets/ProfileDropDown/ProfileDropDown";
import Skeleton from "../../other/Skeleton/Skeleton";
import Notifications from "./widgets/Notifications";
import NotificationsGmaeInvite from './widgets/NotificationsGameInvite';

// slices
// import { setAllNotifications } from "../../../store/slices/notificationSlice";

// other
import ROUTES from "../../../routes/routes";
import API from "../../../api/api";

// styles
import classes from "./styles.module.css";

export default function UserPageHeader() {
  const [isNotsOpened, setIsNotsOpened] = useState(false);

  const {unreadNotifications} = useSelector(s => s.notificationSlice);
  const {loginedUserData} = useSelector(s => s.userDataSlice);
  const {userId} = useSelector(s => s.userAuthSlice);

  // const dispatch = useDispatch();

  const fetchNotificationsList = () => {
    // userId &&
    // API.notificationList(userId)
    // .then(d => {
    //   if (d?.message === 'foundNotifications') {
    //     dispatch(setAllNotifications(d.data));
    //   }
    // });
  }

  useEffect(() => {
    fetchNotificationsList();
    
    const fetchMinutesInterval = .5 * 60 * 1000;
    const interval = setInterval(() => {
      fetchNotificationsList();
    }, fetchMinutesInterval);

    return () => {
      clearInterval(interval);
    }
  }, [userId]);
  
  const notificationsToggleButton = () => {
    setIsNotsOpened(prev => !prev)
  }

  // const messageButton = () => {
  //   navigate(ROUTES.USER_MESSAGES);
  // };

  return (
    <header className={classes.header}>
      <div className={classes.headerContainer}>
        <div>
          <Link to={ROUTES.USER_HOME} className={classes.link}>
            Chesslogo
          </Link>
        </div>

        <div className={classes.actions}>
          <CustomSearch />

          {/* <button className={classes.headerSvg} onClick={messageButton}>
            <SvgIcon iconName="icon_message" />
          </button> */}

          <div className={classes.notificationsContainer}>
            <button className={classes.headerSvg} onClick={notificationsToggleButton}>
              <SvgIcon iconName="icon_notification" />
              {
                unreadNotifications.length > 0 &&
                <p className={`${classes.notificationCount} ${(unreadNotifications.length < 10) ? classes.notificationCountSmall : classes.notificationCountLarge}`}>{unreadNotifications.length}</p>
              }
            </button>
            {
              isNotsOpened &&
              <Notifications doHideNotifications={() => setIsNotsOpened(false)}/>
            }
          </div>

          <div className={classes.headerProfileBox}>
            <Link to={`${ROUTES.USER_PROFILE}/${loginedUserData?._id}`}>
              <Skeleton
                imgSrc={((loginedUserData && loginedUserData?.profilePhoto) ? API.img(loginedUserData.profilePhoto) : '/default_profile_picture.png')}
                imgAlt="profile_picture"
                imgClassName={classes.headerProfilePicture}
              />
            </Link>
            <ProfileDropDown />
          </div>
        </div>
      </div>
      
      <NotificationsGmaeInvite />
    </header>
  );
}
