// libs
import { useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";

// components
import CustomButton from "../../../../../components/custom/CustomButton";

// other
import API from "../../../../../api/api";
import ROUTES from "../../../../../routes/routes";

// styles
import classes from "./styles.module.css";

export default function ChangeLevel({ data }) {
  const [selectedLevelInd, setSelectedLevelInd] = useState(0);
  const levels = ["New In Chess", "Amateur", "Proffesional", "Chess Coach"];
  const navigate = useNavigate();

  const registerButton = () => {
    const sendingData = {
      email: data.email,
      password: data.pass,
      chessLevel: selectedLevelInd + 1,
    };
  
    API.resgisterNewUser(sendingData)
    .then(d => {
      if (d.user) {
        navigate(ROUTES.SIGN_IN);
      }
    })
  }

  return createPortal(
    <div className={classes.root + " center"}>
      <div className={classes.box}>
        <h2 className={classes.headerText}>
          One Final Step! <br />
          Please, Choose your Chess level!
        </h2>

        <div className={classes.levels}>
          {levels.map((item, i) => (
            <CustomButton key={i} buttonText={item} className={classes.level + " " + (selectedLevelInd === i && classes.levelSelected)} onClick={() => setSelectedLevelInd(i)}/>
          ))}
        </div>

        <CustomButton buttonText="Sign up" className={classes.signUpButton} onClick={registerButton}/>
      </div>
    </div>,
    document.body
  );
}
