// other
import countriesData from "../../../data/countries";

// styles
import classes from "./styles.module.css";

export default function UserPageEditCountry({ country, setCountry }) {
  return (
    <div className={classes.root}>
      <p className={classes.title}>Location</p>
      <select onChange={(e) => setCountry(e.target.value)} className={classes.select} value={country}>
        <option value="" hidden>
          (not selected)
        </option>
        {countriesData.map((countryName, i) => (
          <option
            value={countryName}
            key={i}
            className={classes.option}
          >
            {countryName}
          </option>
        ))}
      </select>
    </div>
  );
}
