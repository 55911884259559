// components
import Flag from "../../../../../components/other/Flag";

// styles
import classes from "./styles.module.css";

export default function ProfileInfo({ userData }) {
  return (
    <div className={classes.userData}>
      <div className={classes.usernameBox}>
        <div className={classes.nameSurnameFlag}>
          <h2>
            {/* {userData?.firstname} {userData?.lastname} */}
            Name Surname
          </h2>
          <Flag coutryName={"Armenia"} isShowCountryName={true} />
        </div>
        <div className={classes.rating}>
          <p className={classes.ratingType}>
            Bullet <span>100</span>
          </p>
          <p className={classes.ratingType}>
            Rapid <span>200</span>
          </p>
          <p className={classes.ratingType}>
            Blits <span>500</span>
          </p>
        </div>
        <p className={classes.username}>@username</p>
      </div>

      {/* TODO: fetch played games, tournaments, victories when backend is will be done */}
      <div className={classes.counters}>
        <p className={classes.counter}>
          <span>13</span> Played Games
        </p>
        <p className={classes.counter}>
          <span>0</span> Tournaments
        </p>
        <p className={classes.counter}>
          <span>4</span> Victories
        </p>
        <p className={classes.counter}>
          <span>10</span> Followers
        </p>
      </div>

      <p className={classes.profileDescription}>About text about this user.</p>
    </div>
  );
}
