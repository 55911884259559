// components
import SvgIcon from "../../../../../components/other/SvgIcon";

export default function UserButtons({
  classes,
  newPostButton,
  editProfileButton,
}) {
  return (
    <>
      <button className={classes.newPostButton} onClick={newPostButton}>
        <SvgIcon iconName="icon_plus" className={classes.buttonSvg} />
        New Post
      </button>

      <button className={classes.editProfileButton} onClick={editProfileButton}>
        <SvgIcon iconName="icon_pen" className={classes.buttonSvg} />
        <span>Edit Profile</span>
      </button>
    </>
  );
}
