import { createSlice } from "@reduxjs/toolkit";
import ST from "../../api/localStorage";

const keys = ST.KEYS;

const userAuthSlice = createSlice({
  name: 'userAuthSlice',

  initialState: {
    accessToken: ST.get(ST.KEYS.ACCESS_TOKEN),
    refreshToken: ST.get(ST.KEYS.REFRESH_TOKEN),
    userId: ST.get(ST.KEYS.USER_ID),
    gameId: ST.get(ST.KEYS.GAME_ID),
  },

  reducers: {
    setAccessToken(state, actions) {
      state.accessToken = actions.payload;
      ST.set(keys.ACCESS_TOKEN, actions.payload);
    },
    
    setRefreshToken(state, actions) {
      state.refreshToken = actions.payload;
      ST.set(keys.REFRESH_TOKEN, actions.payload);
    },

    setUserId(state, actions) {
      state.userId = actions.payload;
      ST.set(keys.USER_ID, actions.payload);
    },

    setGameId(state, actions) {
      state.gameId = actions.payload;
      ST.set(keys.GAME_ID, actions.payload);
    },

    setFullData(state, actions) {
      state.accessToken = actions.payload.accessToken;
      state.refreshToken = actions.payload.refreshToken;
      state.userId = actions.payload.userId;
      ST.set(keys.ACCESS_TOKEN, actions.payload.accessToken);
      ST.set(keys.REFRESH_TOKEN, actions.payload.refreshToken);
      ST.set(keys.USER_ID, actions.payload.userId);
    },
  },
});

export const { setAccessToken, setRefreshToken, setUserId, setGameId, setFullData } = userAuthSlice.actions;
export default userAuthSlice.reducer;