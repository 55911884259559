// libs
import { 
  useState, 
  useRef,
  // useEffect,
 } from "react";
import { useSelector } from "react-redux";

// components
import AddNewPost from "../../../components/other/AddNewPost";
import UserPost from "../../../components/userPage/UserPost";
import OnlineFriends from "../../../components/other/OnlineFriends/OnlineFriends";

// other
// import API from "../../../api/api";

// custom hooks
// import useRefreshToken from "../../../customHooks/useRefreshToken";

// classes
import classes from "./styles.module.css";

export default function UserHome() {
  const { accessToken } = useSelector((s) => s.userAuthSlice);
  // const [,updateRefreshToken] = useRefreshToken();

  const [allPosts, setAllPosts] = useState([]);
  // const [postsPageNumb, setPostsPageNumb] = useState(0);

  // useEffect(() => {
  //   API.allFollowersPosts(accessToken, postsPageNumb)
  //     .then((d) => {
  //       if (d?.data?.data) {
  //         setAllPosts((prev) => [...prev, ...d.data.data]);
  //       }
  //     })
  //     .catch(() => {
  //       updateRefreshToken();
  //     });
  // }, [accessToken, postsPageNumb]);

  const scrollableRef = useRef(null);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const { scrollTop, scrollHeight, clientHeight } = scrollableRef.current;

  //     if (scrollTop + clientHeight >= scrollHeight) {
  //       setPostsPageNumb((prev) => prev + 1);
  //     }
  //   };

  //   scrollableRef.current.addEventListener("scroll", handleScroll);

  //   return () => {
  //     scrollableRef?.current?.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <div className={classes.userHome} ref={scrollableRef}>
      <OnlineFriends
        containerWidthClassName={classes.userHomeWidth}
        accessToken={accessToken}
      />
      <div className={classes.userHomeWidth}>
        <AddNewPost
          buttonText="Post"
          placeholderText="Type Something!"
          className={classes.newPost}
        />

        {allPosts.map((postData, i) => (
          <UserPost key={i} postData={postData} />
        ))}
      </div>
    </div>
  );
}
