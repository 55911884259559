/* eslint-disable react-hooks/exhaustive-deps */
// libs
import { Routes, Route } from 'react-router-dom';

// pages
import Home from './pages/other/Home';
import NotFound from './pages/other/NotFound/NotFound';
// auth pages
import Auth from './pages/auth/Auth';
import AuthCreateAccount from './pages/auth/AuthCreateAccount';
import AuthCreateLoginPass from './pages/auth/AuthCreateLoginPass/AuthCreateLoginPass';
import AuthResetPassword from './pages/auth/AuthResetPassword';
import AuthCreateNewPassword from './pages/auth/AuthCreateNewPassword/AuthCreateNewPassword';
import AuthForgotPassword from './pages/auth/AuthForgotPassword';
import AuthSignIn from './pages/auth/AuthSignIn';
// user pages
import User from './pages/user/User';
import UserPlay from './pages/user/UserPlay';
import UserHome from './pages/user/UserHome/UserHome';
import UserProfile from './pages/user/UserProfile/UserProfile';
// import UserMessages from './pages/user/UserMessages';
// import UserAnalysis from './pages/user/UserAnalysis'
// import UserLearn from './pages/user/UserLearn';
// import UserTournaments from './pages/user/UserTournaments';
// import UserSettings from './pages/user/UserSettings';
// import UserSupport from './pages/user/UserSupport';
// import UserSocialFeed from './pages/user/UserSocialFeed';
// import UserEventNews from './pages/user/UserEventNews';
import UserLogout from './pages/user/UserLogout';

// other
import ROUTES from './routes/routes';

// styles
import './App.css';

function App() {
  return (
    <div className="app">
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.AUTH} element={<Auth />}>
          <Route path={ROUTES.CREATE_ACCOUNT} element={<AuthCreateAccount />} />
          <Route path={`${ROUTES.CREATE_ACCOUNT}/:link`} element={<AuthCreateLoginPass />} />
          <Route path={ROUTES.RESET_PASSWORD} element={<AuthResetPassword />} />
          <Route path={ROUTES.FORGOT_PASSWORD} element={<AuthForgotPassword />} />
          <Route path={`${ROUTES.RESET_PASSWORD_NEW_PASS}/:token`} element={<AuthCreateNewPassword />} />
          <Route path={ROUTES.SIGN_IN} element={<AuthSignIn />} />
        </Route>
        <Route path={ROUTES.USER} element={<User />}>
          <Route path={ROUTES.USER_HOME} element={<UserHome />} />
          <Route path={ROUTES.USER_PLAY} element={<UserPlay />} />
          <Route path={`${ROUTES.USER_PROFILE}/:userId`} element={<UserProfile />} />
          {/* <Route path={ROUTES.USER_MESSAGES} element={<UserMessages />} /> */}
          {/* <Route path={ROUTES.USER_ANALYSIS} element={<UserAnalysis />} /> */}
          {/* <Route path={ROUTES.USER_LEARN} element={<UserLearn />} /> */}
          {/* <Route path={ROUTES.USER_TOURNAMENTS} element={<UserTournaments />} /> */}
          {/* <Route path={ROUTES.USER_SETTINGS} element={<UserSettings />} /> */}
          {/* <Route path={ROUTES.USER_SUPPORT} element={<UserSupport />} /> */}
          {/* <Route path={ROUTES.USER_EVENT_NEWS} element={<UserEventNews />}/> */}
          {/* <Route path={ROUTES.USER_FEED} element={<UserSocialFeed />}/> */}
        </Route>
        <Route path={ROUTES.USER_LOGOUT} element={<UserLogout />} />
        <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
