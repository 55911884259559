// libs
import { 
  useDispatch,
  useSelector,
} from "react-redux";

// components
import NotificationSection from "../NotificationSection/NotificationSection";

// slices
import { notificationsStatusSetRead } from "../../../../../store/slices/notificationSlice";

// styles
import classes from "./styles.module.css";

export default function Notifications({
  doHideNotifications,
}) {
  const {unreadNotifications, readedNotifications } = useSelector(s => s.notificationSlice);
  const dispatch = useDispatch();

  const doSetViewedNotificationByNotificationId = (notificationId) => {
    dispatch(notificationsStatusSetRead(notificationId));
  };

  return (
    <div className={classes.root} onClick={doHideNotifications}>
      <div
        className={classes.notifications}
        onClick={(e) => e.stopPropagation()}
      >
        <p className={classes.headerText}>Notifications</p>

        <NotificationSection
          sectionName="New"
          doSetViewedNotificationByNotificationId={doSetViewedNotificationByNotificationId}
          notifications={unreadNotifications}
        />

        <NotificationSection
          sectionName="Previously"
          notifications={readedNotifications}
        />
      </div>
    </div>
  );
}
