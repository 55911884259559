// libs
import React from "react";

// styles
import classes from "./styles.module.css";

export default function CustomInput({
  value,
  setValue,
  inputType = "text",
  isDisabled = false,
  placeholder = "(change placeholder)",
  hasError = false,
  errorMessage = "(error message change)",
}) {
  return (
    <div className={classes.inputBox}>
      <input
        placeholder={placeholder}
        className={`${classes.input} ${value !== '' && hasError && classes.inputError}`}
        type={inputType}
        value={value}
        disabled={isDisabled}
        onChange={(e) => setValue(e.target.value)}
      />
      
      {
        value !== '' && hasError && <p className={classes.errorMessage}>{errorMessage}</p>
      }
    </div>
  );
}
