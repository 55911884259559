// libs
import React from "react";

// components
import Question from "../../other/Question";

// other
import questions from "./questions.js";

// styles
import classes from "./styles.module.css";

export default function SectionQuestions() {
  const [openedIndex, setOpenedIndex] = React.useState(-1);

  return (
    <div className={classes.questions}>
      <div className="container">
        <h1 className={classes.headerText}>Popular questions</h1>

        {questions.map((item, i) => (
          <Question
            key={i}
            questionIndex={i}
            openedIndex={openedIndex}
            data={item}
            setOpenedIndex={setOpenedIndex}
          />
        ))}
      </div>
    </div>
  );
}
