class ST {
  static KEYS = {
    ACCESS_TOKEN: "access_token",
    REFRESH_TOKEN: "refresh_token",
    USER_ID: "user_id",
    GAME_ID: "game_id",
  };

  constructor() {
    throw new Error("not instance: Storage");
  }

  static get(itemName) {
    console.groupCollapsed('> ST.get: ', itemName);
    console.log( localStorage.getItem(itemName));
    console.groupEnd();
    const item = JSON.parse(localStorage.getItem(itemName));

    !item && localStorage.setItem(itemName, null);

    return item;
  }

  static set(itemName, data) {
    console.groupCollapsed(`< ST.set: ${itemName}`);
    console.log(data);
    console.groupEnd();
    localStorage.setItem(itemName, JSON.stringify(data));
  }
}

export default ST;
