// libs
import React from "react";
// import { useSelector } from "react-redux";

// components
import NotificationItem from "../NotificationItem/NotificationItem";

// other
// import API from "../../../../../api/api";

// styles
import classes from "./styles.module.css";

export default function NotificationSection({
  sectionName,
  notifications,
  // doSetViewedNotificationByNotificationId,
}) {
  // const { accessToken } = useSelector((s) => s.userAuthSlice);

  const readedNotification = (notificationId) => {
    // API.readedNotificationLink(notificationId, accessToken).then((d) => {
    //   if (d?.message === "foundOneNotification") {
    //     doSetViewedNotificationByNotificationId(notificationId);
    //   }
    // });
  };

  return (
    <>
      {/* <p className={classes.sectionName}>{sectionName}</p> */}
      {notifications.map((notification, i) => (
        <React.Fragment key={i}>
          {sectionName === "New" ? (
            <div onClick={() => readedNotification(notification._id)} className={classes.notViewed}>
              <NotificationItem notification={notification} />
            </div>
          ) : (
            <NotificationItem notification={notification} />
          )}
        </React.Fragment>
      ))}
    </>
  );
}
