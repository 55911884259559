import { createSlice } from "@reduxjs/toolkit";

const currentFigureSlice = createSlice({
  name: 'currentFigureSlice',
  initialState: {
    selectedFigure: null,
    from: { vertically: -1, horizontally: -1 },
  },
  
  reducers: {
    figureClick(state, { payload }) {
      const { i, j, figureData } = payload;

      if (state.selectedFigure === null || state.selectedFigure?.color === figureData?.color) {
        state.selectedFigure = figureData;
        state.from = { vertically: i, horizontally: j };
      }
    },

    resetAll(state) {
      state.selectedFigure = null;
      state.from = { vertically: -1, horizontally: -1 };
    }
  },
});

export const { figureClick, resetAll } = currentFigureSlice.actions;
export default currentFigureSlice.reducer;