// components
import SvgIcon from "../../../../other/SvgIcon";
import MiniMenu from "../../../../other/MiniMenu";
import Reactions from "./widgets/Reactions";

export default function Statistics({
  classes,
  likersId,
  sheirersId,
  postId,
  setShowComments,
}) {
  const countsCheck = (count) => {
    let res = count;
    if (count >= 1000) {
      res = Math.round(count / 1000) + "k+";
    }
    if (count >= 1000000) {
      res = Math.round(count / 1000000) + "kk+";
    }

    return res;
  };

  const showMoreRepost = [
    {
      title: "Repost this post",
      iconName: "icon_report",
      event: () => {
        // TODO
      },
    },

    {
      title: "Undo my repost",
      iconName: "icon_block",
      event: () => {
        // TODO
      },
    },
  ];

  const repostButton = () => {
    // TODO
  };
  
  return (
    <div className={classes.statistics}>
      <button className={classes.statisticButton} onClick={() => setShowComments(prev => !prev)}>
        <SvgIcon iconName="icon_message_2" />
        <p className={classes.statisticCount}>{countsCheck(1)}</p>
      </button>

      <div className={classes.statisticButton} onClick={repostButton}>
        <MiniMenu iconName="icon_reply" list={showMoreRepost} />
        <p className={classes.statisticCount}>
          {countsCheck(sheirersId.length)}
        </p>
      </div>

      <Reactions likersId={likersId} postId={postId} />
    </div>
  );
}
