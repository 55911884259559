// libs
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// components
import SvgIcon from "../../other/SvgIcon";
import CustomInputForModal from "../../custom/CustomInputForModal";
import CustomButton from "../../custom/CustomButton";
import ChangeEmail from "./modals/ChangeEmail";
import ChangePassword from "./modals/ChangePassword";
import ChangeUsername from "./modals/ChangeUsername";
import Skeleton from "../../other/Skeleton/Skeleton";
import CustomImgLoad from "../../custom/CustomImgLoad/CustomImgLoad";
import UserPageEditCountry from "../UserPageEditCountry";

// other
import API from "../../../api/api";

// slice
import { updateUserData } from "../../../store/slices/userDataSlice";

// classes
import classes from "./styles.module.css";

export default function UserPageEditModal({ modalCloseButton }) {
  const profileInfo =  {
    firstname: "Name",
    lastname: "Surname",
    aboutMe: "About text about this user.",
    country: "Armenia",
    username: "username",
    email: "username@gmail.com",
  };
  const dispatch = useDispatch();
  const { accessToken, userId } = useSelector((s) => s.userAuthSlice);
  const { loginedUserData } = useSelector(s => s.userDataSlice);
  
  const [firstName, setFirstName] = useState(profileInfo.firstname);
  const [lastName, setLastName] = useState(profileInfo.lastname);
  const [aboutMe, setAboutMe] = useState(profileInfo.aboutMe);
  const [country, setCountry] = useState(profileInfo.country);
  const [modalShow, setModalShow] = useState(1);

  const modalClose = () => {
    if (modalShow === 1) {
      modalCloseButton();
    } else {
      setModalShow(1);
    }
  };

  const bannerDelete = () => {
    // API.updateProfileBanner(null, accessToken, userId)
    // .then(d => {
    //   if (d?.message === 'updated') {
    //     dispatch(updateUserData((d.data)));
    //   }
    // });
  };

  const bannerChange = (file) => {
    // file.length > 0 &&
    // API.updateProfileBanner(file, accessToken, userId)
    // .then(d => {
    //   if (d?.message === 'updated') {
    //     dispatch(updateUserData((d.data)));
    //   }
    // });
  };

  const profileImgChange = (file) => {
    // file.length > 0 &&
    // API.updateProfileImg(file, accessToken, userId)
    // .then(d => {
    //   if (d?.message === 'updated') {
    //     dispatch(updateUserData((d.data)));
    //   }
    // });
  };

  const saveButton = () => {
    // API.updateProfileSpecificData(
    //   accessToken,
    //   userId,
    //   firstName,
    //   lastName,
    //   aboutMe,
    //   country,
    // ).then((d) => {
    //   if (d.message === "updated") {
    //     dispatch(updateUserData((d.data)));
    //     modalClose();
    //   }
    // });
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.header}>
          <p>
            {modalShow === 1 && "Edit Profile"}
            {modalShow === 2 && "Change Username"}
            {modalShow === 3 && "Change Email"}
            {modalShow === 4 && "Change Password"}
          </p>
          <button onClick={modalClose}>
            <SvgIcon iconName="icon_close" className={classes.svgClose} />
          </button>
        </div>

        {modalShow === 1 && (
          <>
            <p className={classes.headerText}>Edit Cover Image</p>
            <div className={classes.bannerBox}>
              <div className={classes.bannerContainer}>
                <div className={classes.bannerButtons}>
                  <CustomImgLoad setFiles={bannerChange}>
                    <div
                      className={
                        classes.imageButton + " " + classes.bannerButton
                      }
                    >
                      <SvgIcon iconName="icon_camera" />
                    </div>
                  </CustomImgLoad>

                  {loginedUserData && loginedUserData?.backgroundPhoto && (
                    <button onClick={bannerDelete}>
                      <div
                        className={
                          classes.imageButton + " " + classes.bannerButton
                        }
                      >
                        <SvgIcon iconName="icon_delete" />
                      </div>
                    </button>
                  )}
                </div>

                <Skeleton
                  imgSrc={
                    loginedUserData && loginedUserData?.backgroundPhoto
                      ? API.img(loginedUserData.backgroundPhoto)
                      : "/default_profile_banner.png"
                  }
                  imgAlt="profile_banner"
                  imgClassName={classes.banner}
                />
              </div>

              <div className={classes.profilePictureBox}>
                <CustomImgLoad setFiles={profileImgChange}>
                  <div
                    className={
                      classes.imageButton + " " + classes.profilePictureButton
                    }
                  >
                    <SvgIcon iconName="icon_camera" />
                  </div>
                </CustomImgLoad>
                <Skeleton
                  imgSrc={
                    loginedUserData && loginedUserData.profilePhoto
                      ? API.img(loginedUserData.profilePhoto)
                      : "/default_profile_picture.png"
                  }
                  imgAlt="profile_picture"
                  imgClassName={classes.profilePicture}
                />
              </div>
            </div>

            <p className={classes.changeImageText}>Change Profile Image</p>

            <div className={classes.inputs}>
              <CustomInputForModal
                title="Username"
                placeholder={profileInfo.username}
                hasChangeButton={true}
                isDisabled={true}
                onClick={() => {
                  setModalShow(2);
                }}
              />
              <CustomInputForModal
                title="Email"
                hasChangeButton={true}
                isDisabled={true}
                placeholder={profileInfo.email}
                onClick={() => {
                  setModalShow(3);
                }}
              />
              <CustomInputForModal
                title="Password"
                hasChangeButton={true}
                isDisabled={true}
                placeholder="xxxxxxxxxxxxx"
                onClick={() => {
                  setModalShow(4);
                }}
              />
              <CustomInputForModal
                title="Firstname"
                value={firstName}
                setValue={setFirstName}
              />
              <CustomInputForModal
                title="Lastname"
                value={lastName}
                setValue={setLastName}
              />

              <UserPageEditCountry
                country={country}
                setCountry={setCountry}
              />

              <CustomInputForModal
                title="About me"
                value={aboutMe}
                setValue={setAboutMe}
                isLargeInput={true}
              />

              <CustomButton
                className={classes.saveButton}
                buttonText="Save"
                onClick={saveButton}
              />
            </div>
          </>
        )}

        {modalShow === 2 && (
          <ChangeUsername setModalShow={setModalShow} closeModal={modalClose} />
        )}
        {modalShow === 3 && (
          <ChangeEmail setModalShow={setModalShow} closeModal={modalClose} />
        )}
        {modalShow === 4 && (
          <ChangePassword setModalShow={setModalShow} closeModal={modalClose} />
        )}
      </div>
    </div>
  );
}
