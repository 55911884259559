// libs
import { useNavigate } from "react-router-dom";

// components
import SectionDescription from "../../sections/SectionDescription";
import CustomButton from "../../custom/CustomButton/CustomButton";

// other
import ROUTES from "../../../routes/routes";

// styles
import classes from "./styles.module.css";

export default function SectionChessLovers() {
  const navigate = useNavigate();

  return (
    <SectionDescription>
      <div className={`container ${classes.box}`}>
        <h2 className={classes.headerText}>
          <span>Social Platform</span> for chess lovers
        </h2>
        <p className={classes.p}>
          Our Social chess platform create a vibrant community where players can
          connect, interact, and engage with fellow enthusiasts, sharing
          strategies, tips, and experiences.
        </p>
        <p className={classes.p}>
          Chess-Stealth is an exclusive online chess club that contains elements
          of a social platform at the same time. The platform integrates a
          number of unique features, meeting the various needs and preferences
          of users, as well as bringing together chess enthusiasts and
          professional chess players in one place.
        </p>
        <CustomButton 
          buttonText="Let's Start"
          onClick={() => navigate(ROUTES.USER_PLAY)}
        />
      </div>
    </SectionDescription>
  );
}
