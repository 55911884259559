// libs
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// components
import RenderMoves from "./widgets/RenderMoves/RenderMoves";
import ShareAndStepControlButtons from "./widgets/ShareAndStepControlButtons";

// styles
import classes from "./styles.module.css";

export default function ShowedMoves() {
  const [movesCount, setMovesCount] = useState([]);
  const { showingSteps, showingStepsIndex } = useSelector(
    (s) => s.chessBoardSlice
  );

  useEffect(() => {
    setMovesCount(
      Array.from(
        { length: Math.round(showingSteps.length / 2) },
        (_, index) => index
      )
    );
  }, [showingSteps.length]);

  return (
    <div className={classes.root}>
      <div>(show opponent eated firues and timer)</div>
      <RenderMoves
        movesCount={movesCount}
        showingSteps={showingSteps}
        showingStepsIndex={showingStepsIndex}
      />
      <div>(show my eated firues and timer)</div>
      <ShareAndStepControlButtons />
    </div>
  );
}
