// libs
import { useState, useEffect } from "react";
import { useLocation, useNavigate, Outlet} from "react-router-dom";
import { 
  useSelector, 
  // useDispatch,
} from "react-redux";

// components
import UserRightSideBar from "../../../components/userPage/UserRightSideBar/UserRightSideBar";
import UserLeftSideBar from "../../../components/userPage/UserLeftSideBar/UserLeftSideBar";
import UserPageHeader from "../../../components/userPage/UserPageHeader";

// other
import ROUTES from "../../../routes/routes";
// import API from "../../../api/api";

// slices
// import { updateUserData } from "../../../store/slices/userDataSlice";

//styles
import classes from "./styles.module.css";

export default function User() {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { accessToken, refreshToken, userId } = useSelector(s => s.userAuthSlice);
  const [showRigthSideBar, setShowRigthSideBar] = useState(true);

  useEffect(() => {
    if (pathname === ROUTES.USER || pathname === ROUTES.USER + "/") {
      navigate(ROUTES.USER_HOME);
      return;
    }

    // right side bar hide check
    if (
      pathname === ROUTES.USER_PLAY ||
      pathname === ROUTES.USER_MESSAGES
    ) {
      setShowRigthSideBar(false);
    } else {
      setShowRigthSideBar(true);
    }

    // localStorage keys names
    if (
      !accessToken ||
      !refreshToken ||
      !userId
    ) {
      // navigate(ROUTES.USER_LOGOUT);
    }
  }, [pathname]);

  // useEffect(() => {
  //   API.getProfileInfo(userId)
  //   .then(d => dispatch(updateUserData(d.data)));
  // }, []);

  return (
    <div className={classes.userPage}>
      <UserPageHeader />

      <div
        className={`${classes.content} ${((showRigthSideBar === false )&& classes.contentWithoutRightSideBar)}`}
      >
        <div className={classes.leftSideBarBox}>
          <UserLeftSideBar/>
        </div>

        <main className={classes.main}>
          <Outlet />
        </main>

        {
          showRigthSideBar ? (
            <div className={classes.rightSideBarBox}>
              <UserRightSideBar />
            </div>
          ) : <div className={`${classes.miniRightSideBar} ${((showRigthSideBar === false) && classes.miniRightSideBar_1)}`}></div>
        }
      </div>
    </div>
  );
}
