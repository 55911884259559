// libs
import { useDispatch } from "react-redux";

// components
import SvgIcon from "../../../SvgIcon";

// slices
import {
  showingStepsIndIncrement,
  showingStepsIndDecrement,
} from "../../../../../store/slices/chessBoardSlice";

// styles
import classes from "./styles.module.css";

export default function ShareAndStepControlButtons() {
  const dispatch = useDispatch();

  const buttons = [
    {
      iconName: "icon_createGame",
      handler: () => {
        // TODO
      },
      isDisabled: true,
    },
    {
      iconName: "icon_shareGame",
      handler: () => {
        // TODO
      },
      isDisabled: true,
    },
    {
      iconName: "icon_arrow_left_2",
      handler: () => {
        dispatch(showingStepsIndDecrement());
      },
    },
    {
      iconName: "icon_arrow_right",
      handler: () => {
        dispatch(showingStepsIndIncrement());
      },
    },
    {
      iconName: "icon_flag",
      handler: () => {
        // TODO
      },
      isDisabled: true,
    },
  ];

  return (
    <div className={classes.root}>
      {buttons.map((buttonData, i) => (
        <button
          key={i}
          className={classes.button}
          disabled={buttonData.isDisabled}
          onClick={buttonData.handler}
        >
          <SvgIcon iconName={buttonData.iconName} width={21} height={21} />
        </button>
      ))}
    </div>
  );
}
