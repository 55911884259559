import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState: {
    allNotifications: [],
    unreadNotifications: [],
    readedNotifications: [],
    gamesInvites: [],
    isInviteApplied: false,
  },
  reducers: {
    setAllNotifications(state, actions) {
      state.allNotifications = actions.payload.reverse();

      // reset subtype notifications
      state.unreadNotifications = [];
      state.readedNotifications = [];
      state.gamesInvites = [];

      // sorting notifications for types
      for (const notific of state.allNotifications) {
        if(notific.type === 'inviteGame') {
          state.gamesInvites.push(notific);
        } else {
          if (notific.isViewed) {
            state.readedNotifications.push(notific);
          } else {
            state.unreadNotifications.push(notific);
          }
        }
      }
    },
    notificationsStatusSetRead(state, actions) {
      const readedNotificId = actions.payload;

      const newUnreadNotifications = [];
      for (let i = 0; i < state.unreadNotifications.length; ++i) {
        const unReadNotific = state.unreadNotifications[i];

        if (unReadNotific._id === readedNotificId) {
          // remove in new unread and push in readed
          unReadNotific.isViewed = true;
          state.readedNotifications.unshift(unReadNotific);
        } else {
          // push new unread
          newUnreadNotifications.unshift(unReadNotific);
        }
      }

      state.unreadNotifications = newUnreadNotifications
    },
    setGamesInvites(state, actions) {
      state.gamesInvites = actions.payload;
    },
    removeGameInvite(state, actions) {
      const newGameInvites = [];
      const removingIndex = actions.payload;

      for (let i = 0; i < state.gamesInvites.length; ++i) {
        const gameInvite = state.gamesInvites[i];
        if(i !== removingIndex){ 
          newGameInvites.push(gameInvite);
        }
      }

      state.gamesInvites = newGameInvites;
    },
    setIsInviteApplied(state, actions) {
      state.isInviteApplied = actions.payload;
    },
  },
});

export const {
  setAllNotifications,
  notificationsStatusSetRead,
  setGamesInvites,
  removeGameInvite,
  setIsInviteApplied,
} = notificationSlice.actions;

export default notificationSlice.reducer;