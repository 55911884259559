// componetns
import GameType from '../../../../../components/other/GameType/GameType';

// customHooks
import useGameType from '../../../../../customHooks/useGameType';

// styles
import classes from './styles.module.css';

export default function CreateGame({ socket, closeModal }) {
  const [gameStartData, setGameStartData] = useGameType(null);

  const startButton = () => {
    if (
      gameStartData?.gameTime?.gameTime > -1 &&
      gameStartData?.gameTime?.addingTime > -1 &&
      gameStartData?.type &&
      gameStartData?.preferredColor
    ) {
      console.log(gameStartData);
      // socket.emit("start", gameStartData);
      closeModal();
    }
  }

  return (
    <div className={classes.root}>
      <p>Create Game</p>

      <GameType setGameTypes={setGameStartData}/>  
      <button onClick={startButton} className={classes.startButton}>Start Play</button>
    </div>
  )
}