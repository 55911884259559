// components
import SectionDescription from "../../sections/SectionDescription";

// styles
import classes from "./styles.module.css";

export default function SectionAbout() {
  return (
    <SectionDescription>
      <div className={`container ${classes.box}`}>
        <h2 className={classes.headerText}>
          About Platform <span>Chess Title</span>
        </h2>
        <p className={classes.p}>
          The platform integrates a number of unique features, meeting the
          various needs and preferences of users, as well as bringing together
          chess enthusiasts and professional chess players in one place.
        </p>
        <p className={classes.p}>
          One of the main features of the platform is to create a customized
          chess game. The game will be with the most comfortable interface, with
          the possibility of applying various filters.
        </p>
        <p className={classes.p}>
          We allows you to discover the game of chess from new aspects. It aims
          to unite chess players in one social platform with various unique
          features and benefits.
        </p>
      </div>
    </SectionDescription>
  );
}
