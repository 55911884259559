// libs
import { Link } from "react-router-dom";

// components
import SvgIcon from "../../../../other/SvgIcon";

// styles
import classes from "./styles.module.css";

export default function SideBarItem({
  itemData,
  isActiveSectionId,
  isDisabled,
}) {
  const { svg, title, route } = itemData;

  return (
    <>
      {isDisabled ? (
        <div className={`${classes.section} ${isDisabled && classes.disabledLink}`}>
          <SvgIcon iconName={svg} className={classes.svg} />
          <span>{title}</span>
        </div>
      ) : (
        <Link to={route} className={classes.link}>
          <button
            className={`${classes.section} ${
              isActiveSectionId && classes.activeSection
            }`}
          >
            <SvgIcon iconName={svg} className={classes.svg} />
            <span>{title}</span>
          </button>
        </Link>
      )}
    </>
  );
}
