// libs
import { useNavigate } from "react-router-dom";

// styles
import classes from "./styles.module.css";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <div>
        <h1>Page Not Found</h1>
        <button onClick={() => navigate(-1)} className={classes.link}>Go to back</button>
      </div>
    </div>
  );
}
