const logos = {
  logo_facebook: (
    <>
      <g clipPath="url(#clip0_199_1255)">
        <path
          d="M36 18C36 26.9845 29.4173 34.4313 20.8125 35.7813V23.2031H25.0066L25.8047 18H20.8125V14.6236C20.8125 13.1998 21.51 11.8125 23.7459 11.8125H26.0156V7.38281C26.0156 7.38281 23.9555 7.03125 21.986 7.03125C17.8748 7.03125 15.1875 9.52312 15.1875 14.0344V18H10.6172V23.2031H15.1875V35.7813C6.58266 34.4313 0 26.9845 0 18C0 8.05922 8.05922 0 18 0C27.9408 0 36 8.05922 36 18Z"
          fill="#1877F2"
        />
        <path
          d="M25.0066 23.2031L25.8047 18H20.8125V14.6235C20.8125 13.2 21.5099 11.8125 23.7459 11.8125H26.0156V7.38281C26.0156 7.38281 23.9558 7.03125 21.9864 7.03125C17.8748 7.03125 15.1875 9.52313 15.1875 14.0344V18H10.6172V23.2031H15.1875V35.7813C16.104 35.9251 17.0432 36 18 36C18.9568 36 19.896 35.9251 20.8125 35.7813V23.2031H25.0066Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_199_1255">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
  logo_google: (
    <>
      <g clipPath="url(#clip0_199_1260)">
        <path
          d="M34.6407 14.8703L19.9567 14.8696C19.3082 14.8696 18.7827 15.3951 18.7827 16.0436V20.7345C18.7827 21.3827 19.3082 21.9084 19.9566 21.9084H28.2258C27.3203 24.2583 25.6302 26.2263 23.474 27.4767L27 33.5805C32.6561 30.3093 36 24.5698 36 18.1448C36 17.2299 35.9326 16.576 35.7977 15.8396C35.6952 15.2801 35.2095 14.8703 34.6407 14.8703Z"
          fill="#167EE6"
        />
        <path
          d="M18 28.9565C13.9532 28.9565 10.4205 26.7455 8.52307 23.4736L2.41953 26.9917C5.52558 32.3749 11.3442 36 18 36C21.2651 36 24.346 35.1209 27 33.5889V33.5805L23.474 27.4767C21.8612 28.4121 19.9948 28.9565 18 28.9565Z"
          fill="#12B347"
        />
        <path
          d="M27 33.5888V33.5804L23.474 27.4766C21.8612 28.4119 19.995 28.9564 18 28.9564V35.9999C21.2651 35.9999 24.3461 35.1208 27 33.5888Z"
          fill="#0F993E"
        />
        <path
          d="M7.04348 17.9999C7.04348 16.0052 7.58784 14.1391 8.52307 12.5263L2.41952 9.0083C0.879047 11.6539 0 14.7265 0 17.9999C0 21.2734 0.879047 24.3459 2.41952 26.9916L8.52307 23.4735C7.58784 21.8608 7.04348 19.9946 7.04348 17.9999Z"
          fill="#FFD500"
        />
        <path
          d="M18 7.04349C20.6389 7.04349 23.0629 7.98117 24.9562 9.54091C25.4232 9.92566 26.1021 9.89789 26.53 9.47004L29.8536 6.14637C30.3391 5.66093 30.3045 4.86633 29.7859 4.41647C26.6138 1.66451 22.4864 0 18 0C11.3442 0 5.52558 3.6251 2.41953 9.00837L8.52307 12.5264C10.4205 9.25453 13.9532 7.04349 18 7.04349Z"
          fill="#FF4B26"
        />
        <path
          d="M24.9562 9.54091C25.4232 9.92566 26.1022 9.89789 26.53 9.47004L29.8536 6.14637C30.339 5.66093 30.3044 4.86633 29.7859 4.41647C26.6138 1.66444 22.4864 0 18 0V7.04348C20.6388 7.04348 23.0629 7.98117 24.9562 9.54091Z"
          fill="#D93F21"
        />
      </g>
      <defs>
        <clipPath id="clip0_199_1260">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
};

export default logos;