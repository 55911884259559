// components
import CustomButton from "../../custom/CustomButton/CustomButton";
import {Link} from 'react-router-dom'

// images
import rook from "../../../assets/img/landingPage/rook.webp";

// other
import ROUTES from "../../../routes/routes";

// styles
import classes from "./styles.module.css";

export default function SectionWelcome() {
  return (
    <section className={classes.welcome}>
      <div className={`${classes.box} ${classes.box_1} center`}>
        <div className={classes.contentBox}>
          <h1 className={classes.headerText}>
            <span>Chess</span>Title
          </h1>
          <p className={classes.description}>Play! Learn! Conquer!</p>
          <p className={classes.longDescription}>
            Discover the game of chess from new aspects. It aims to unite chess
            players in one social platform with various unique features and
            benefits.
          </p>
          <Link to={ROUTES.SIGN_IN}>
            <CustomButton buttonText="Start Play" />
          </Link>
        </div>
      </div>
      <div
        className={`${classes.box} ${classes.box_2}`}
        style={{ backgroundImage: `url('${rook}')` }}
      ></div>
    </section>
  );
}
