import { createSlice } from "@reduxjs/toolkit";

const userDataSlice = createSlice({
  name: 'userDataSlice',
  initialState: {
    loginedUserData: null,
  },
  reducers: {
    updateUserData(state, actions) {
      state.loginedUserData = actions.payload;
    }
  },
});

export const {updateUserData} = userDataSlice.actions;
export default userDataSlice.reducer;