// libs
import { useState } from "react";

// components
import SvgIcon from "../SvgIcon";

// styles
import classes from "./styles.module.css";

export default function Question({
  data,
  openedIndex,
  questionIndex,
  setOpenedIndex,
}) {
  const { question, answer } = data;

  const answerToggle = () => {
    if (openedIndex === questionIndex) {
      setOpenedIndex(-1);
    } else {
      setOpenedIndex(questionIndex);
    }
  };

  return (
    <div className={classes.questionBox}>
      <div className={classes.questionBlock} onClick={answerToggle}>
        <p className={classes.question}>{question}</p>
        <div
          className={
            classes.iconBox + (openedIndex === questionIndex ? " rotate" : "")
          }
        >
          <SvgIcon iconName="icon_arrow_down" />
        </div>
      </div>
      {openedIndex === questionIndex && (
        <p className={classes.answer}>{answer}</p>
      )}
    </div>
  );
}
