// libs
import { useNavigate } from "react-router-dom";

// components
import CustomButton from "../../custom/CustomButton";
import FrendsPlaying from "./widgets/FrendsPlaying";
import Skeleton from "../../other/Skeleton/Skeleton";

// other
import ROUTES from "../../../routes/routes";

// styles
import classes from "./styles.module.css";

export default function UserRightSideBar() {
  const navigate = useNavigate();

  const navigateToPlayPage = () => {
    navigate(ROUTES.USER_PLAY);
  };

  return (
    <div className={classes.rightSideBar}>
      <Skeleton
        imgSrc="/chessboard.png"
        imgAlt="chessBoard"
        imgClassName={classes.chessboardImg}
      />

      <div className={classes.buttonsBox}>
        <CustomButton
          buttonText="New Game"
          className={classes.newGame}
          onClick={navigateToPlayPage}
        />
        <CustomButton
          buttonText="Vs Computer"
          className={classes.button}
          onClick={navigateToPlayPage}
        />
        <CustomButton
          buttonText="Vs Friend"
          className={classes.button}
          onClick={navigateToPlayPage}
        />
      </div>

      <FrendsPlaying />
    </div>
  );
}
