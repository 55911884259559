// images
import figureImg from '../../../assets/img/landingPage/figures.webp';

// styles
import classes from './styles.module.css';

export default function SectionDescription({ children }) {
  return (
    <div 
      className={`${classes.description} center`}
      style={{ backgroundImage: `url('${figureImg}')` }}
    >
      { children }
    </div>
  )
}
