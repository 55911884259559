import SERVER_PATH from "./serverPath";

class API {
  static #server = SERVER_PATH;

  static #deleteNotificationLink = `${API.#server}/notification`;
  static #lastFivePlayersLink = `${API.#server}/game/findMyOpponent`;
  static #userSearchLink = `${API.#server}/user/search`;
  static #readedNotificationLink = `${API.#server}/notification`;
  static #deleteSubCommentLink = `${API.#server}/subComment`;
  static #unlikeSubcommentLink = `${API.#server}/subComment/unlike`;
  static #likeSubCommentLink = `${API.#server}/subComment/like`;
  static #subGetAllSubCommentsByCommentIdLink = `${API.#server}/subComment/byComment`;
  static #subcommentCreateLink = `${API.#server}/subComment`;
  static #unlikeCommentLink = `${API.#server}/comment/unlike`;
  static #notificationListLink = `${API.#server}/notification/byUser`;
  static #commentLikeLink = `${API.#server}/comment/like`;
  static #postUnlikeLink = `${API.#server}/post/unlike`;
  static #userOnlineFriendsListLink = `${API.#server}/user/myOnlineFriends`;
  static #updateProfileEmailLink = `${API.#server}/user/email`;
  static #updateProfilePasswordLink = `${API.#server}/user/password`;
  static #updateProfileUsernameLink = `${API.#server}/user/username`;
  static #updateProfileBanner = `${API.#server}/user/backgroundPhoto`;
  static #updateProfileImgLink = `${API.#server}/user/profilePhoto`;
  static #updateProfileSpecificDataLink = `${API.#server}/user`;
  static #unfollowUserLink = `${API.#server}/user/unfollow`;
  static #followUserLink = `${API.#server}/user/following`;
  static #getAllUsersLink = `${API.#server}/user`;
  static #registerWithEmailPassLevelLink = `${API.#server}/auth/register`;
  static #deleteCommentLink = `${API.#server}/comment`;
  static #allCommentsLink = `${API.#server}/comment/byPost`;
  static #addCommentLink = `${API.#server}/comment`;
  static #deletePostLink = `${API.#server}/post`;
  static #addPostByIdLink = `${API.#server}/post/byUserId`;
  static #userPostLink = `${API.#server}/post/myPost`;
  static #likePostLink = `${API.#server}/post/like`;
  static #registerLink = `${API.#server}/auth/sendRegisterLink`;
  static #loginLink = `${API.#server}/auth/login`;
  static #logoutLink = `${API.#server}/auth/logout`;
  static #registerUsernamePassLink = `${API.#server}/auth/register`;
  static #refreshTokenLink = `${API.#server}/auth/newAccesstoken`;
  static #forgotPassLink = `${API.#server}/auth/sendForgotpasswordLink`;
  static #forgotPassNewPassLink = `${API.#server}/auth/forgotpassword`;
  static #activeGamesLink = `${API.#server}/game/active`;
  static #folowindPostsLink = `${API.#server}/post/myFollowingPost`;
  static #imgLink = `${API.#server}/file`;
  static #profileInfoLink = `${API.#server}/user`;
  static #addPostLink = `${API.#server}/post`;

  constructor() {
    throw new Error("cant has instance");
  }

  // PRIVATE METHODS
  static #createBody(data) {
    const body = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    return body;
  }

  // PUBLIC METHODS
  static async deleteNotificationById(notificationId, token) {
    return fetch(`${API.#deleteNotificationLink}/${notificationId}`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    .then(r => r.json());
  }
  static async lastFivePlayers(token) {
    return fetch(`${API.#lastFivePlayersLink}`, {
      method: 'GET',
      headers: {
        'content-type': "application/json",
        Authorization: `Bearer ${token}`
      }
    })
    .then(r => r.json());
  }
  static async userSearch(searchingText, token) {
    return fetch(`${API.#userSearchLink}/${searchingText}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(r => r.json())
  }
  static async readedNotificationLink(notificationItem, token) {
    return fetch(`${API.#readedNotificationLink}/${notificationItem}`,{
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(r => r.json());
  }
  static async deleteSubComment(subCommentId, token) {
    return fetch(`${API.#deleteSubCommentLink}/${subCommentId}`, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((r) => r.json());
  }
  static async unlikeSubComment(subCommentId, token) {
    return fetch(`${API.#unlikeSubcommentLink}/${subCommentId}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((r) => r.json());
  }
  static async likeSubcomment(subCommentId, token) {
    return fetch(`${API.#likeSubCommentLink}/${subCommentId}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((r) => r.json());
  }
  static async getAllSubCommentsByCommentId(commentId, token) {
    return fetch(`${API.#subGetAllSubCommentsByCommentIdLink}/${commentId}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((r) => r.json());
  }
  static async subcommentCreate(commentId, text, token) {
    return fetch(API.#subcommentCreateLink, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        commentId,
        text,
      }),
    }).then((r) => r.json());
  }

  static async unlikeComment(commentId, token) {
    return fetch(`${API.#unlikeCommentLink}/${commentId}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((r) => r.json());
  }
  static async notificationList(userId) {
    return fetch(`${API.#notificationListLink}/${userId}`, {
      method: "GET",
      "content-type": "application/json",
    }).then((r) => r.json());
  }
  static async commentLike(commentId, token) {
    return fetch(API.#commentLikeLink + "/" + commentId, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((r) => r.json());
  }

  static async unlikePost(postId, token) {
    return fetch(API.#postUnlikeLink + "/" + postId, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((r) => r.json());
  }
  static async getOnlineFriendsList(token) {
    return fetch(API.#userOnlineFriendsListLink, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((r) => r.json());
  }
  static async updateProfileEmail(email, password, userId, token) {
    const sendData = {
      email,
      password,
    };

    return fetch(API.#updateProfileEmailLink + "/" + userId, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(sendData),
    }).then((r) => r.json());
  }

  static async updateProfilePasswordLink(
    oldPassword,
    newPassword,
    userId,
    token
  ) {
    const sendData = {
      password: oldPassword,
      newPassword,
    };

    return fetch(API.#updateProfilePasswordLink + "/" + userId, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(sendData),
    }).then((r) => r.json());
  }
  static async updateProfileSpecificData(
    token,
    userId,
    firstname,
    lastname,
    aboutMe,
    country
  ) {
    const sendData = {};

    if (firstname && firstname.trim() !== "") {
      sendData.firstname = firstname;
    }
    if (firstname && lastname.trim() !== "") {
      sendData.lastname = lastname;
    }
    if (aboutMe && aboutMe.trim() !== "") {
      sendData.aboutMe = aboutMe;
    }
    if (country && country.trim() !== "") {
      sendData.country = country;
    }

    return fetch(API.#updateProfileSpecificDataLink + "/" + userId, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(sendData),
    }).then((r) => r.json());
  }
  static async updateProfileBanner(file, token, userId) {
    const formData = new FormData();

    if (file !== null) {
      formData.append("file", file[0] ?? null);
    }

    return fetch(`${API.#updateProfileBanner}/${userId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }).then((r) => r.json());
  }
  static async updateProfileImg(file, token, userId) {
    const formData = new FormData();

    if (file !== null) {
      formData.append("file", file[0] ?? null);
    }

    return fetch(`${API.#updateProfileImgLink}/${userId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }).then((r) => r.json());
  }
  static async updateProfileUsername(userId, password, username, token) {
    return fetch(API.#updateProfileUsernameLink + "/" + userId, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        password,
        username,
      }),
    }).then((r) => r.json());
  }

  static async unfollowUser(token, userId) {
    return fetch(API.#unfollowUserLink, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
      }),
    }).then((r) => r.json());
  }
  static async followUser(token, userId) {
    return fetch(API.#followUserLink, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
      }),
    }).then((r) => r.json());
  }
  static async getAllUsersList() {
    return fetch(API.#getAllUsersLink).then((r) => r.json());
  }
  static async resgisterNewUser(userData) {
    return fetch(API.#registerWithEmailPassLevelLink, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(userData),
    }).then((r) => r.json());
  }
  static async deleteCommentById(commentId) {
    return fetch(API.#deleteCommentLink + "/" + commentId, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    }).then((r) => r.json());
  }

  static async getAllCommentsByPostId(postId) {
    return fetch(API.#allCommentsLink + "/" + postId).then((r) => r.json());
  }

  static async addComment(token, postId, commentText) {
    return fetch(API.#addCommentLink, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        postId,
        text: commentText,
      }),
    }).then((r) => r.json());
  }

  static async deleteUserPost(token, postId) {
    return fetch(API.#deletePostLink + "/" + postId, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((r) => r.json());
  }

  static async getUserPostsById(token, pageNumber = 0, userId) {
    return fetch(`${API.#addPostByIdLink}?pageNumber=${pageNumber}&limit=10&userId=${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((r) => {
      return r.json();
    });
  }

  static async getUserPosts(token, pageNumber = 0) {
    return fetch(`${API.#userPostLink}?pageNumber=${pageNumber}&limit=10`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((r) => {
      return r.json();
    });
  }

  static async likePost(token, postId, reactionNumber) {
    return fetch(API.#likePostLink + "/" + postId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        reactionNumber,
      }),
    }).then((r) => {
      return r.json();
    });
  }

  static async registerUserMail(inputMail) {
    return fetch(API.#registerLink, API.#createBody({ email: inputMail })).then(
      (r) => r.json()
    );
  }

  static async registerLoginPass(link, username, password) {
    return fetch(`${API.#registerUsernamePassLink}/${link}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    }).then((r) => r.json());
  }

  static async login(email, password) {
    return fetch(API.#loginLink, API.#createBody({ password, email })).then(
      (r) => r.json()
    );
  }

  static async logout(token) {
    fetch(API.#logoutLink, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async refreshToken(refreshToken) {
    return fetch(API.#refreshTokenLink, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${refreshToken}`,
      },
    }).then((r) => r.json());
  }

  static async forgotPass(email) {
    return fetch(API.#forgotPassLink, API.#createBody({ email })).then((r) =>
      r.json()
    );
  }

  static async forgotPassNewPass(token, password) {
    return fetch(
      API.#forgotPassNewPassLink + "/" + token,
      API.#createBody({ password })
    ).then((r) => r.json());
  }

  static async getUserMessages() {
    // TODO: fetch real messages
    const data = [
      {
        img: "http://surl.li/pykuf",
        firstName: "Name",
        secondName: "Secondname",
        time: "11:23 am",
        isPremiumPlayer: true,
        lastMessage: "Lorem ipsum dolor sit amet consectetur",
      },
      {
        img: "http://surl.li/pykuf",
        firstName: "Name",
        secondName: "Secondname",
        time: "07:30 pm",
        isPremiumPlayer: false,
        lastMessage: "Lorem ipsum dolor sit amet consectetur",
      },
      {
        img: "http://surl.li/pykuf",
        firstName: "Name ",
        secondName: "Secondname",
        time: "12:10 am",
        isPremiumPlayer: true,
        lastMessage: "Lorem ipsum dolor sit amet consectetur",
      },
    ];

    return Promise.apply(data);
  }

  static async getActiveGames(token) {
    return fetch(`${API.#activeGamesLink}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((r) => r.json());
  }

  static async allFollowersPosts(token, pageNumber) {
    return fetch(
      `${API.#folowindPostsLink}?pageNumber=${pageNumber}&limit=10`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((r) => r.json());
  }

  static img(imageId) {
    return API.#imgLink + "/" + imageId;
  }

  static async getProfileInfo(userId) {
    return fetch(API.#profileInfoLink + "/" + userId).then((r) => r.json());
  }

  static async newPost(token, files, title) {
    const formData = new FormData();

    if (files !== null) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    }

    formData.append("title", title);

    return fetch(`${API.#addPostLink}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }).then((r) => r.json());
  }
}

export default API;
