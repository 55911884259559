// libs
import React from "react";

// components
import SvgIcon from '../../../components/other/SvgIcon';

// other
import piecesImages from "./piecesImages";

// styles
import classes from './styles.module.css';

export default function FigureNotConnection({ figureData }) {

  const setFigureName = (figureColor, figureName) => {
    const figures = piecesImages[figureColor]; 
    return figures[figureName];
  };

  return (
    <div className="center" style={{height: '100%'}}>
      <SvgIcon
        className={figureData.color === 'white' ? classes.whiteFigure : classes.blackFigure}
        iconName={setFigureName(figureData.color, figureData.pieces)}
        width={40}
        height={40}
      />
    </div>
  );
}
